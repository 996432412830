import React from 'react';

import {Stack} from '@mui/material';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';

import CustomizedSnackbars from 'Components/Snackbar/Snackbar';

/**
 * Snackbar Container
 * @return {ReactElement}
 */
export default function SnackbarContainer() {
  const snackbars = useSelector((state) => state.snackbarState);
  const {pathname} = useLocation();
  console.log('snackbars', snackbars);
  // purchase page should not show any toast
  if (pathname === '/me/purchase') {
    return null;
  }
  return (
    <Stack spacing={2} sx={{
      bottom: '44px',
      left: '24px',
      right: 'auto',
      position: 'fixed',
      zIndex: 1400,
    }}>
      {snackbars && snackbars.map((item) =>
        <CustomizedSnackbars
          key={item.id}
          id={item.id}
          message={item.message}
          severity={item.severity}
          variant={item.variant}
          noIcon={item.noIcon}
          verticalPosition={item.verticalPosition}
          theme={item.theme}
        />
      )}
    </Stack>
  );
}

