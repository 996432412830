import {miniSerializeError} from '@reduxjs/toolkit';

import {HttpError} from 'Error/HttpError';

export const httpErrorSerializer = (e) => {
  if (e instanceof HttpError) {
    return {
      ...miniSerializeError(e),
      body: e.body,
      errorCode: e.errorCode,
    };
  }
  return miniSerializeError(e);
};
