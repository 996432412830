/* eslint-disable */
import {delCookie, getCookie} from './CookieUtil.js';

import {OFFICE365_ADDIN_LINK, OFFICE365_SDK_LINK} from 'Utils/consts.js';

export class ZoomSchedulerSDK {
  constructor() {
    this.zoomSchedulerToken = '';
    this.popupDialog = null;
  }

  initOffice() {
    const historyCache = {
      replaceState: window.history.replaceState,
      pushState: window.history.pushState,
    };
    const script = document.createElement('script');
    script.src = OFFICE365_SDK_LINK;
    script.onload = () => {
      Office && Office?.onReady(() => {
        // Office js deletes window.history.pushState and window.history.replaceState. Restore them
        window.history.replaceState = historyCache.replaceState;
        window.history.pushState = historyCache.pushState;
        // if (officeHost.context?.ui?.addHandlerAsync) {
        //   officeHost.context.ui.addHandlerAsync(officeHost?.EventType?.DialogParentMessageReceived, this.onMessageFrom365);
        // }
      });
    };
    document.head.appendChild(script);
  };

  getSchedulerToken() {
    const zoomSchedulerToken = getCookie('zsak');
    if (zoomSchedulerToken) {
      this.zoomSchedulerToken = zoomSchedulerToken;
    }

    return this.zoomSchedulerToken;
  };

  logout() {
    delCookie('zak');
    delCookie('zsak');
    window.location.href = OFFICE365_ADDIN_LINK + '/login?redirect=scheduler&signout=true';
  };

  openDialog(
    startAddress, options = {height: 75, width: 70}, msgHandler, eventHandler) {
    const self = this;
    // eslint-disable-next-line
    function dialogCallBack(result) {
      if (result.status == 'succeeded') {
        self.popupDialog = result.value;

        if (self.popupDialog != null && msgHandler && eventHandler) {
          self.popupDialog.addEventHandler(Office.EventType.DialogMessageReceived, msgHandler);
          self.popupDialog.addEventHandler(Office.EventType.DialogEventReceived, eventHandler);
        } else {
        }
      } else {
        console.error('Error in displaying the dialog', result);
      }
    }
    if (typeof Office.context.ui.displayDialogAsync === 'function') {
      Office.context.ui.displayDialogAsync(startAddress, options, dialogCallBack);
    } else {
      window.open(startAddress, '_blank');
    }
  };

  setInvitation(invitation, callback) {
    // const self = this;
    return new Office.Promise(function() {
      Office.context.mailbox.item.body.getTypeAsync(function(result) {
        if (result.status == Office.AsyncResultStatus.Failed) {
          Office.context.mailbox.item.body.setAsync(invitation, {coercionType: Office.CoercionType.Text}, callback);
        } else {
          Office.context.mailbox.item.body.setAsync(invitation, {coercionType: result.value}, callback);
        }
      });
    });
  };
};
const ZoomSchedulerSDKClient = window?.location?.href?.indexOf('clientType=calFor365') > 0 ? new ZoomSchedulerSDK() : {};
export default ZoomSchedulerSDKClient;
