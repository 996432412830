import {defaultHeaders, doFetch, tryGetRespBody} from './ApiUtils';

import {HttpError} from 'Error/HttpError';
import {
  skipCaptchaTests,
} from 'Utils';

const useProxy =
  process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

let zschedulerURL = 'zscheduler/v1';
if (useProxy) {
  // use proxy on dev env for cors issue
  zschedulerURL = 'api/zscheduler/v1';
}
const captchaUrl = `${zschedulerURL}/validators/captcha`;

// Header to send back double-submit cookie csrf protection
export const CSRF_HASH_HEADER = 'CSRF-Token';

// Header to pass token info for server to auth GET event request
export const BOOKER_TOK_HEADER = 'Booker-Token';

// Store the current double-submit hash value
// Optionally read hash from url for SLA + E2E test captcha workaround
let hashHeader = new URLSearchParams(window.location.search).get('hash') || '';

const externalFetchCall = async (url, method, data, headers={...defaultHeaders}) => {
  const addHeaders = {...headers, [CSRF_HASH_HEADER]: hashHeader};

  const resp = await doFetch(
    url,
    method,
    data,
    addHeaders,
  );
  if (!resp.ok) {
    throw new HttpError(resp, await tryGetRespBody(resp));
  } else {
    // Server may refresh cookie token -- refresh the csrf hash header value if present
    const hash = resp.headers.get(CSRF_HASH_HEADER);
    if (hash) {
      hashHeader = hash;
    }

    try {
      const reponseBody = await resp.json();
      return reponseBody;
    } catch (error) {
      throw new Error(error, 'unable to parse json');
    }
  }
};

const externalFetchCallWithErrorBody = async (url, method, data, headers={...defaultHeaders}) => {
  const addHeaders = {...headers, [CSRF_HASH_HEADER]: hashHeader};

  const resp = await doFetch(
    url,
    method,
    data,
    addHeaders,
  );
  if (!resp.ok) {
    throw new HttpError(resp, await tryGetRespBody(resp));
  } else {
    // Server may refresh cookie token -- refresh the csrf hash header value if present
    const hash = resp.headers.get(CSRF_HASH_HEADER);
    if (hash) {
      hashHeader = hash;
    }
    return {};
  }
};

/**
 * Fetch Appointment/Event
 * @param {string} calId
 * @param {string} apptId
 * @param {?Object} query
 * @param {?Object} headers
 * @return {Promise}
 */
export const fetchAppointment = async (calId, apptId, query, headers) => {
  const queryParams = new URLSearchParams({
    ...query,
    user: calId,
  });
  const url = `${zschedulerURL}/appointments/${apptId}/availableTimes?${queryParams.toString()}`;
  return await externalFetchCall(url, 'GET', undefined, {...defaultHeaders, ...headers});
};

/**
 * Fetches multiple appointments, will throw the error of the first request to fail
 * @param {string} slug
 * @param {Array<string>} apptIds
 * @param {?Object} query
 * @return {Promise}
 */
export const fetchAppointments = async (slug, apptIds, query) => {
  return Promise.all(apptIds.map((apptId) => fetchAppointment(slug, apptId, query)));
};
/**
 * Get Appointment Detail
 * @param {string} calId
 * @param {string} apptId
 * @param {?Object} query
 * @param {?Object} headers
 * @return {Promise}
 */
export const getApptDetail = async (calId, apptId, query, headers) => {
  const queryParams = new URLSearchParams({
    ...query,
    user: calId,
  });
  const url = `${zschedulerURL}/appointments/${apptId}?${queryParams.toString()}`;
  return await externalFetchCall(url, 'GET', undefined, {...defaultHeaders, ...headers});
};

export const fetchGlobalLanding = async (calId, query) => {
  const queryParams = new URLSearchParams({
    ...query,
    user: calId,
  });
  const url = `${zschedulerURL}/appointments?${queryParams.toString()}`;
  return await externalFetchCall(url, 'GET');
};

export const fetchForm = async (formSlug, query) => {
  const queryParams = new URLSearchParams({
    ...query,
  });
  const url = `${zschedulerURL}/routing/forms/form-name?${queryParams.toString()}`;
  return await externalFetchCall(url, 'GET');
};

export const submitResponses = async (query, data) => {
  const addTestFlag = skipCaptchas || process.env.NODE_ENV === 'development' ? '&testing=true' : '';
  const queryParams = new URLSearchParams({
    ...query,
  });
  const url = `${zschedulerURL}/routing/forms/form-name/submit?${queryParams.toString()}` + addTestFlag;
  return await externalFetchCall(url, 'POST', data);
};

export const bookAppointmentSlot = async (calId, apptId, data) => {
  const addTestFlag = skipCaptchas || process.env.NODE_ENV === 'development' ? '&testing=true' : '';
  const url = `${zschedulerURL}/attendees?user=${calId}` + addTestFlag;
  data.appointmentId = apptId;
  return await externalFetchCall(url, 'POST', data);
};

export const rescheduleBookedEvent = async (attendeeId, data) => {
  const addTestFlag = skipCaptchas || process.env.NODE_ENV === 'development' ? '?testing=true' : '';
  const url = `${zschedulerURL}/attendees/${attendeeId}` + addTestFlag;
  return await externalFetchCall(url, 'PATCH', data);
};

export const bookVotingPoll = async (calId, data) => {
  const addTestFlag = skipCaptchas || process.env.NODE_ENV === 'development' ? '&testing=true' : '';
  const url = `${zschedulerURL}/pollVotes?user=${calId}` + addTestFlag;
  return await externalFetchCall(url, 'POST', data);
};

export const patchVotingPoll = async (calId, eId, data) => {
  const addTestFlag = skipCaptchas || process.env.NODE_ENV === 'development' ? '&testing=true' : '';
  const url = `${zschedulerURL}/pollVotes/${eId}?user=${calId}` + addTestFlag;
  return await externalFetchCall(url, 'PATCH', data);
};

/**
 *
 * @param {String} attendeeId
 * @param {Object} data
 * @param {?String} data.comment
 * @return {Promise}
 */
export const cancelBookedEvent = async (attendeeId, data) => {
  const addTestFlag = skipCaptchas || process.env.NODE_ENV === 'development' ? '?testing=true' : '';
  const url = `${zschedulerURL}/attendees/${attendeeId}` + addTestFlag;
  return await externalFetchCallWithErrorBody(url, 'DELETE', data);
};

/**
 *
 * @param {String} attendeeId
 * @return {Promise}
 */
export const fetchBookedEvent = async (attendeeId) => {
  const url = `${zschedulerURL}/attendees/${attendeeId}`;
  return await externalFetchCall(url, 'GET');
};

const skipCaptchas = skipCaptchaTests();

/**
 * Send verification email to booker with passcode
 * @param {string} calId host's calendarId
 * @param {Object} data
 * @param {string} data.email booker's email to verify
 * @param {string} data.captcha captcha challenge passcode
 * @param {string} data.host host's calendarId
 * @return {Promise}
 */
export const sendVerificationEmail = async (calId, data) => {
  const addTestFlag = skipCaptchas || process.env.NODE_ENV === 'development' ? '&testing=true' : '';
  const url = `${zschedulerURL}/validators/code?user=${calId}` + addTestFlag;
  return await externalFetchCallWithErrorBody(url, 'POST', data);
};

export const fetchJwtToken = async (calId, passcode) => {
  const addTestFlag = skipCaptchas || process.env.NODE_ENV === 'development' ? '&testing=true' : '';
  const url = `${captchaUrl}?user=${calId}` + addTestFlag;
  const resp = await doFetch(
    url,
    'POST',
    {
      passcode,
    },
    {
      ...defaultHeaders,
    }
  );
  if (!resp.ok) {
    throw new HttpError(resp, await tryGetRespBody(resp));
  } else {
    const hash = resp.headers.get(CSRF_HASH_HEADER);
    if (!hash) {
      console.warn('No csrf hash found');
    } else {
      hashHeader = hash;
    }
  }
};

/**
 * get domain logo request
 * @param {string} calId host's calendarId
 * @return {Promise}
 */
export const getDomainLogoExtFromServer = async (calId) => {
  const url = `${zschedulerURL}/settings/logo?user=${calId}`;
  return await externalFetchCall(url, 'GET');
};

export const resetHash = () => {
  hashHeader = '';
};


/*
 * External get profile picture request
 * @return {Promise}
 */
export const fetchProfilePicture = async (user) => {
  const url = `${zschedulerURL}/settings/picture?user=${user}`;
  return await externalFetchCall(url, 'GET');
};

/*
 * External get display name request
 * @return {Promise}
 */
export const getDisplayName = async (user) => {
  const url = `${zschedulerURL}/settings/displayName?user=${user}`;
  return await externalFetchCall(url, 'GET');
};

/*
 * External get user info request
 * @return {Promise}
 */
export const getExternalUserInfo = async (user) => {
  const url = `${zschedulerURL}/user?user=${user}`;
  return await externalFetchCall(url, 'GET');
};
