import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {
  assignManagedEvent,
  cloneManagedEvent,
  deleteManagedEvent,
  getGroups,
  getManagedEventAssignDetail,
  getManagedEvents,
  patchManagedEvent,
  postManagedEvent,
  unassignManagedEvent,
} from 'Api/ZCalendar';

export const fetchManagedEvents = createAsyncThunk(
  'managedEvents/fetchManagedEvents',
  async () => {
    return getManagedEvents();
  }
);

export const getManagedEventItemThunk = createAsyncThunk(
  'managedEvents/getManagedEventItemThunk',
  async (apptId) => {
    return getManagedEvents(apptId);
  }
);

export const getManagedEventAssignDetailThunk = createAsyncThunk(
  'managedEvents/getManagedEventAssignDetailThunk',
  ({apptId, type}) => getManagedEventAssignDetail(apptId, type)
);

export const removeManagedEvent = createAsyncThunk(
  'managedEvents/deleteManagedEvent',
  (id) => deleteManagedEvent(id)
);

export const editManagedEvent = createAsyncThunk(
  'managedEvents/editManagedEvent',
  async ({apptId, data}, thunkAPI) => patchManagedEvent(apptId, data),
);

export const createManagedEvent = createAsyncThunk(
  'managedEvents/createManagedEvent',
  ({calId, data}, thunkAPI) => postManagedEvent(calId, data),
);

export const fetchGroupsList = createAsyncThunk(
  'managedEvents/fetchGroupsList',
  () => getGroups(),
);

export const assignManagedEventThunk = createAsyncThunk(
  'managedEvents/assignManagedEvent',
  ({apptId, data}, thunkAPI) => assignManagedEvent(apptId, data),
);

export const unassignManagedEventThunk = createAsyncThunk(
  'managedEvents/unassignManagedEvent',
  ({apptId, userId}) => unassignManagedEvent(apptId, userId),
);

// /zscheduler/v1/organization/appointments/([^\s/\\]+)/clone
export const cloneManagedEventThunk = createAsyncThunk(
  'managedEvents/cloneManagedEvent',
  ({apptId}) => cloneManagedEvent(apptId),
);

const slice = createSlice({
  name: 'ManagedEventsStore',
  initialState: {
    loading: false,
    hasError: false,
    events: [],
    isSearchLoading: false,
    groups: [],
    targetAppt: null,
    targetApptAssignUsers: [],
    targetApptAssignGroups: [],
    dialogInfo: {
      formType: '',
      calendarId: '',
      appointment: null,
      capacityType: '',
      apptType: '',
    },
  },
  reducers: {
    setEditFormInfo: (state, action) => {
      state.dialogInfo = Object.assign(state.dialogInfo, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchManagedEvents.pending, (state, action) => {
      state.loading = true;
      state.hasError = false;
    });
    builder.addCase(fetchManagedEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.events = action.payload?.items;
    });
    builder.addCase(fetchManagedEvents.rejected, (state, action) => {
      state.loading = false;
      state.hasError = true;
    });
    builder.addCase(removeManagedEvent.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeManagedEvent.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeManagedEvent.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchGroupsList.fulfilled, (state, action) => {
      state.groups = action.payload?.groups;
    });
    builder.addCase(getManagedEventItemThunk.pending, (state, action) => {
      state.targetAppt = null;
    });
    builder.addCase(getManagedEventItemThunk.fulfilled, (state, action) => {
      state.targetAppt = action.payload;
    });
    builder.addCase(cloneManagedEventThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(cloneManagedEventThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getManagedEventAssignDetailThunk.fulfilled, (state, action) => {
      const {items, type} = action.payload;
      if (type === 'users') {
        state.targetApptAssignUsers = items;
      } else {
        state.targetApptAssignGroups = items;
      }
    });
  },
});

export const {
  setEditFormInfo,
} = slice.actions;

export default slice.reducer;
