import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  id: undefined,
};

export const captchaState = createSlice({
  name: 'CaptchaState',
  initialState,
  reducers: {
    setCaptchaId: (state, {payload}) => {
      state.id = payload;
    },
  },
});

export const {setCaptchaId} = captchaState.actions;

export default captchaState.reducer;
