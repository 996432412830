// TODO [ZOOM-498363]: Use true consts
export const ErrorMsgHeader404 = 'This link isn\'t valid';
export const ErrorMsgBody404 = 'It looks like this link is no longer active. Ask the organizer to send you a new link.';
export const ErrorMsgHeader500 = 'We couldn\'t load this link';
export const ErrorMsgBody500 = 'Refresh your page, or try again later.';
export const ErrorMsgHeader500BadCalendar = 'The host\'s availability could not be retrieved';
export const ErrorMsgBody500BadCalendar = 'Please contact the organizer to resolve their calendar connection';
export const ErrorMsgHeader400 = 'Invalid request';
export const ErrorMsgBody400 = 'This request cannot be processed';
export const ErrorExtEvent404 = 'GetExtEvent404';
export const ErrorMsgBody400LinkUsed =
'Please ask the host to create a new one-time meeting and then send you the updated booking link.';
