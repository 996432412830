import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  event: null,
};

export const eventState = createSlice({
  name: 'EventStore',
  initialState,
  reducers: {
    setEventState: (state, {payload}) => {
      state.event = payload;
    },
  },
});

export const {setEventState} = eventState.actions;

export default eventState.reducer;
