

/**
 * Error class to represent Fetch Response errors
 */
export class HttpError extends Error {
  /**
     * Create an HttpError
     * @param {Response} response
     * @param {?Object} body
     */
  constructor(response, body = {}) {
    super(body.message || body.error?.message || response.status);
    this.body = body;
    this.name = 'HttpError';
    this.errorCode = response.status;
  }
}

