// eslint-disable-next-line
export function getCookie(name) {
  let arr = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
  arr = document.cookie.match(reg);
  if (arr) {
    return arr[2];
  } else {
    return null;
  }
}

// eslint-disable-next-line
export function delCookie(name) {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure';
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure; path=/';
  // eslint-disable-next-line
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure; path=/; domain=' + window.location.hostname.split('.').slice(-2).join('.');
}
