import React from 'react';

import {
  Stack,
  Typography,
} from '@mui/material';
import {useSelector} from 'react-redux';

import {
  BadgeContainer, BadgeContent, CustomUserLogo, LogoContent, ZmBadgeLogo, ZmLogo, ZmTopBar,
} from './Style';

import zoomLogo from 'assets/svg/zoomLogo.svg';
import {LOGO_EMPTY} from 'Store/LogoStore';
import {APPT_TYPE} from 'Utils/consts';
import zoomPalette from 'zoomPalette';

/**
 * Zoom Product Badge component
 * @param {Object} props
 * @return {ReactElement}
 */
export function ZmProductBadge() {
  return (
    <BadgeContainer>
      <BadgeContent>
        <Typography sx={{
          fontSize: '.875rem',
          lineHeight: '1.125rem',
          fontWeight: '600',
          color: zoomPalette.gray[60],
        }}>
        Powered by
        </Typography>
        <ZmBadgeLogo src={zoomLogo} alt="Zoom Logo" />
      </BadgeContent>
    </BadgeContainer>
  );
}

/**
 * Zoom Web Header Toolbar component
 * @param {Object} props
 * @return {ReactElement}
 */
export default function ZmWebHeader() {
  const logoEnforceUse = useSelector((state) => state.logoState.logoEnforceUse);
  const appt = useSelector((s) => s.bookAppointments.appt);
  const logo = useSelector((state) => state.logoState.logo);
  const isLoaded = useSelector((state) => state.logoState.isLoaded);
  const isTeamAppt = () => {
    return (appt?.poolingType === APPT_TYPE.COLLECTIVE ||
      appt?.poolingType === APPT_TYPE.ROUND_ROBIN);
  };

  let useDefaultZoomLogo = logo === LOGO_EMPTY;

  if ((isTeamAppt() && !logoEnforceUse)) {
    // use default zoom logo for team appt if no account level logo
    // is enforced
    useDefaultZoomLogo = true;
  }
  return (
    <ZmTopBar>
      {isLoaded && (
        <LogoContent isZoom={useDefaultZoomLogo}>
          {(useDefaultZoomLogo) ?
          <ZmLogo
            src={zoomLogo}
            alt="Zoom Logo"
          /> :
          <Stack sx={{
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%',
            gap: '8px',
            overflow: 'hidden',
          }}>
            {/* display logo if appt is not a team appt or
            is a team and admin has set a logo */}
            {(!isTeamAppt() || (isTeamAppt() && logoEnforceUse)) && (
              <CustomUserLogo src={logo} alt="Booking logo" />
            )}
            <ZmProductBadge />
          </Stack>}
        </LogoContent>
      )}
    </ZmTopBar>
  );
}
