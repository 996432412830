import React from 'react';

import {styled} from '@mui/system';

import Spinner40 from 'assets/svg/spinner-light-40.svg';
import Spinner80 from 'assets/svg/spinner-light-80.svg';

const absoluteStyles = (size) => `
  position: absolute;
  top: calc(50% - 0.75*${size}px);
  left: calc(50% - 0.75*${size}px);
`;

/**
 * Spinner component
 * @param {DialogProps} props
 * @return {ReactElement}
 */
export const SpinningImg = styled('img',
  {shouldForwardProp: (propName) => !['size', 'absolute'].includes(propName)})(
  ({absolute, size, theme}) => `
  ${absolute ? absoluteStyles(size) : ''}
  border-radius: ${size}px;
  width: ${size}px;
  height: ${size}px;
  -webkit-animation:spin 1.75s linear infinite;
  -moz-animation:spin 1.75s linear infinite;
  animation:spin 1.75s linear infinite;

  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
  }
  @-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
  }
  @keyframes spin { 
    100% { 
      -webkit-transform: rotate(360deg); 
      transform:rotate(360deg); 
    } 
  }
`);

/**
 * Styled ZmSpinner
 * @param {Object} props
 * @param {Number} props.size
 * @return {ReactElement}
 */
export default function ZmSpinner(props) {
  let finalSize;
  let spinnerComponent;
  switch (props.size) {
    case '14':
      finalSize = '14';
      spinnerComponent = Spinner40;
      break;
    case '40':
      finalSize = '40';
      spinnerComponent = Spinner40;
      break;
    case '80':
      finalSize = '80';
      spinnerComponent = Spinner80;
      break;
    default:
      finalSize = '40';
      spinnerComponent = Spinner40;
      break;
  }
  return (
    <SpinningImg
      role="progressbar"
      aria-valuetext="Loading..."
      aria-busy="true"
      size={finalSize}
      src={spinnerComponent}
      absolute={props?.absolute}
    />
  );
}
