import {Stack, Typography} from '@mui/material';
import {styled} from '@mui/system';

export const ContentContainer = styled(Stack)(({theme}) => ({
  padding: '3.5rem 2rem 2rem',
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
  alignItems: 'center',
}));

export const AvatarContainer = styled(Stack)(({theme}) => ({
  padding: '1rem 2rem',
  margin: 'auto',
  alignItems: 'center',
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
}));

export const TypographyH5 = styled(Typography)(({theme}) => ({
  color: theme.palette.text.primary,
  fontWeight: '700',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  marginTop: '10px',
}));

export const TypographyBody2 = styled(Typography)(({theme}) => ({
  color: theme.palette.text.primary,
  fontWeight: '400',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  marginTop: '10px',
}));
