import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  errorType: '',
  errorMsg: '',
};

export const errorState = createSlice({
  name: 'ErrorState',
  initialState,
  reducers: {
    setErrorState: (state, {payload}) => {
      state.errorType = payload.errorType;
      state.errorMsg = payload.errorMsg;
    },
  },
});

export const {setErrorState} = errorState.actions;

export default errorState.reducer;
