import {useCallback, useContext} from 'react';

import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import CaptchaContext from 'Components/Captcha/CaptchaContext';
import {useCaptcha, useCaptchaPasscode} from 'Components/Captcha/useCaptcha';
import {UnauthorizedError} from 'Error/UnauthorizedError';
import {setErrorState} from 'Store/ErrorStore';
import {skipCaptchaTests} from 'Utils';

/**
 * return error handler with navigate
 * @param {String} errorType
 * @return {Function}
 */
export function useErrorHandler(errorType) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (errorMsg) => {
    dispatch(
      setErrorState({
        errorType,
        errorMsg: errorMsg || 'test error',
      })
    );
    navigate('/error');
  };
}

const THRESHOLD_MS = 3*1000;

/**
 * wrap query with retry captcha
 * @param {Function} query
 * @param {String} calId
 * @return {Function}
 */
export function useQueryWithRetryCaptcha(query, calId) {
  const captchaContext = useContext(CaptchaContext);
  const runCaptcha = useCaptcha(calId);
  const wrapper = useCallback(async function(...args) {
    try {
      return await query(...args);
    } catch (error) {
      // if error is UnauthorizedError, we call Captcha, and increate the count
      if (error instanceof UnauthorizedError) {
        // get jwt token, call cb, and then return cb value
        try {
          const shouldRefreshCookieToken = (
            new Date().valueOf() - captchaContext.current.lastCookieTokenRefreshedTime > THRESHOLD_MS
          );

          if (shouldRefreshCookieToken) {
            captchaContext.current.cookieTokenPromise = new Promise((resolve, reject) => {
              captchaContext.current.lastCookieTokenRefreshedTime = new Date().valueOf();
              runCaptcha(resolve, reject);
            });
          } // else re-use the last cookieTokenPromise result

          await captchaContext.current.cookieTokenPromise;
          // if fetch jwt token successfully, re-run the function
          return await query(...args);
        } catch (error) {
          console.error('Captcha Verify error', error);
          throw error;
        }
      }
      throw error;
    }
  }, [query, runCaptcha, captchaContext]);
  return wrapper;
}

/**
 * wrap query with retry captcha
 * @param {Function} query
 * @return {Function}
 */
export function useQueryWithCaptchaPasscode(query) {
  const runCaptcha = useCaptchaPasscode();
  const wrapper = useCallback(async function(...args) {
    const captchaPasscode = await new Promise((resolve, reject) => {
      if (!skipCaptchaTests()) {
        runCaptcha(resolve, reject);
      } else {
        resolve('passcode');
      }
    });
    return await query(...args, captchaPasscode);
  }, [query, runCaptcha]);
  return wrapper;
}

/**
 * @param {Object} param0
 * @return {Function}
 */
export function useEnhancedQuery({calendarId, onError, query}) {
  const captchaWrapper = useQueryWithRetryCaptcha(query, calendarId);
  const wrappedQuery = useCallback(async function(...args) {
    try {
      const res = await captchaWrapper(...args);
      return res;
    } catch (error) {
      onError(error);
    }
  }, [captchaWrapper, onError]);
  return wrappedQuery;
}

/**
 * @param {Object} param0
 * @return {Function}
 */
export function useEnhancedQueryWithoutCaptcha({onError, query}) {
  const wrappedQuery = useCallback(async function(...args) {
    try {
      const res = await query(...args);
      return res;
    } catch (error) {
      onError(error);
    }
  }, [onError, query]);
  return wrappedQuery;
}
