import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {
  getTeams,
  createTeam,
  patchTeam,
  deleteTeam,
  getTeamMembers,
  addTeamMember,
  deleteTeamMember,
  getMemberTeams,
} from 'Api/ZCalendar';
import {getUserInfo} from 'Utils/integration';

export const addTeam = createAsyncThunk(
  'teamStore/addTeam',
  async (data) => {
    const response = await createTeam(data);
    return response;
  }
);

export const editTeam = createAsyncThunk(
  'teamStore/editTeam',
  async (team) => {
    const response = await patchTeam(team);
    return response;
  }
);

export const removeTeam = createAsyncThunk(
  'teamStore/removeTeam',
  async (teamId) => {
    const response = await deleteTeam(teamId);
    return response;
  }
);

export const listTeams = createAsyncThunk(
  'teamStore/listTeams',
  async (data, thunkAPI) => {
    const response = await getTeams();
    return response;
  }
);

// Get the list of teams as administrators and members
export const listManagedAndMemberTeams = createAsyncThunk(
  'teamStore/listManagedAndMemberTeams',
  async (_, thunkAPI) => {
    // console.log('listManagedAndMemberTeams: ', thunkAPI.getState());
    // if (thunkAPI.getState().teamState.listUnionTeamsLoading) {
    //   thunkAPI.abort();
    // }
    const userInfo = getUserInfo();
    const [managedTeams, memberTeams] = await Promise.all([
      getTeams(),
      getMemberTeams(userInfo.userId),
    ]);
    return [managedTeams, memberTeams];
  }
);

export const listTeamMembers = createAsyncThunk(
  'teamStore/listTeamMembers',
  async (data, thunkAPI) => {
    const teamId = data.id;
    const response = await getTeamMembers(teamId);
    return response;
  }
);

export const assignTeamMember = createAsyncThunk(
  'teamStore/assignTeamMember',
  async (data) => {
    const response = await addTeamMember(data);
    return response;
  }
);

export const removeTeamMember = createAsyncThunk(
  'teamStore/removeTeamMember',
  async (data) => {
    const response = await deleteTeamMember(data);
    return response;
  }
);

export const teamState = createSlice({
  name: 'teamStore',
  initialState: {
    teams: [],
    members: [],
    managedTeams: [],
    memberTeams: [],
    listUnionTeamsLoading: false,
    loading: false,
    errors: {
      members: false,
    },
  },
  reducers: {
    setTeamLoading(state, action) {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listTeams.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(listTeams.fulfilled, (state, action) => {
      state.teams = action.payload.teams;
      state.loading = false;
    });
    builder.addCase(listTeams.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(listTeamMembers.pending, (state, action) => {
      state.loading = true;
      state.errors.members = false;
      state.members = [];
    });
    builder.addCase(listTeamMembers.fulfilled, (state, action) => {
      state.members = action.payload.members;
      state.loading = false;
    });
    builder.addCase(listTeamMembers.rejected, (state, action) => {
      state.loading = false;
      state.errors.members = true;
    });
    builder.addCase(addTeam.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addTeam.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addTeam.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editTeam.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editTeam.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editTeam.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeTeam.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeTeam.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeTeam.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(assignTeamMember.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(assignTeamMember.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(assignTeamMember.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeTeamMember.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeTeamMember.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeTeamMember.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(listManagedAndMemberTeams.pending, (state, action) => {
      state.listUnionTeamsLoading = true;
    });
    builder.addCase(listManagedAndMemberTeams.fulfilled, (state, action) => {
      const [managedTeams, memberTeams] = action.payload;
      state.managedTeams = managedTeams.teams;
      state.memberTeams = memberTeams.teams;
      state.listUnionTeamsLoading = false;
    });
    builder.addCase(listManagedAndMemberTeams.rejected, (state, action) => {
      state.listUnionTeamsLoading = false;
    });
  },
});

export const {setTeamLoading} = teamState.actions;

export default teamState.reducer;
