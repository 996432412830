/* eslint-disable require-jsdoc */
/* eslint-disable valid-jsdoc */
import React, {ReactElement} from 'react';

import asyncStats from '@zoom/asynccomm-stats';

import {METRIC_NAME} from 'Utils/consts';

type ErrorBoundaryState = {
  hasError: boolean,
  hasPushedError: boolean,
};

type ErrorBoundaryProps = {
  fallback?: ReactElement,
  children?: ReactElement,
};

/**
 *
 */
export class ErrorBoundary extends React.Component {
  props: ErrorBoundaryProps;
  state: ErrorBoundaryState;
  /**
   *
   * @param {ErrorBoundaryProps} props
   */
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {hasError: false, hasPushedError: false};
  }

  componentDidUpdate(_prevProps: Readonly<ErrorBoundaryProps>, prevState: Readonly<ErrorBoundaryState>): void {
    // Only push error metric on first error detection
    if (this.state.hasError && !this.state.hasPushedError) {
      this.setState({hasPushedError: true, hasError: true});
      asyncStats.report({metricName: METRIC_NAME.UI_ERROR, description: 'fail'});
    }
  }

  /**
   *
   * @param {Error} error
   * @return {void}
   */
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  /**
   *
   * @return {ReactElement}
   */
  render(): ReactElement {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
