import {Box} from '@mui/material';
import {styled} from '@mui/system';

export const ZmTopBar = styled('div')(({theme}) => ({
  position: 'relative',
  height: '56px',
  background: theme.palette.background.default,
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.1)',
  zIndex: 999,
  [theme.breakpoints.down('sm')]: {
    height: '40px',
  },
}));

export const LogoContent = styled(Box,
  {shouldForwardProp: (p) => !['isZoom'].includes(p)}
)(({isZoom, theme}) => {
  return ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      marginLeft: '32px',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      position: 'relative',
    },
  });
});

export const ZmLogo = styled('img')(({theme}) => ({
  height: '20px',
}));

export const CustomUserLogo = styled('img')(({theme}) => ({
  height: '40px',
  [theme.breakpoints.down('sm')]: {
    height: '28px',
  },
}));

export const BadgeContainer = styled(Box)(({theme}) => ({
  'position': 'relative',
  'width': '151px',
  'flexShrink': 1,
  'flexBasis': '151px',
  '@media (max-width: 340px)': {
    display: 'none',
  },
}));

export const BadgeContent = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '6px',
  border: `1px solid ${theme.palette.common.swatch['30']}`,
  borderRadius: '100px',
  height: '20px',
  padding: '0px 8px',
  background: theme.palette.common.white,
  userSelect: 'none',
  whiteSpace: 'nowrap',
  flexWrap: 'nowrap',
  position: 'absolute',
  top: '-9px',
  right: '8px',
}));

export const ZmBadgeLogo = styled('img')(({theme}) => ({
  width: '41.52px',
  height: '9.43px',
  marginTop: '5.29px',
}));
