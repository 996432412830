import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {getPersonalBookingPageColor, patchPersonalBookingPageColor} from 'Api/ZCalendar';
import {HttpError} from 'Error/HttpError';

const initialState = {
  bookingPageColor: {
    id: 'bookingPageColor',
    value: '',
  },
  isLoading: false,
  isLoaded: false,
};

export const getBookingPageColor = createAsyncThunk(
  'bookingPageColor/getBookingPageColor',
  async () => {
    try {
      const response = await getPersonalBookingPageColor();
      return response;
    } catch (e) {
      if (e instanceof HttpError) {
        if (e.errorCode === 404) {
          return ({
            id: 'bookingPageColor',
            value: '',
          });
        }
      }
      throw e;
    }
  }
);

export const putBookingPageColor = createAsyncThunk(
  'logo/patchPersonalBookingPageColor',
  async ({data}) => {
    const response = await patchPersonalBookingPageColor({
      id: 'bookingPageColor',
      value: data,
    });
    return response;
  }
);

export const bookingPageColorState = createSlice({
  name: 'BookingPageColor',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBookingPageColor.fulfilled, (state, action) => {
      state.bookingPageColor.value = action.payload?.value;
      state.isLoading = false;
      state.isLoaded = true;
    });
    builder.addCase(getBookingPageColor.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getBookingPageColor.rejected, (state, action) => {
      state.isLoading = false;
      state.isLoaded = true;
    });
    builder.addCase(putBookingPageColor.fulfilled, (state, action) => {
      state.isLoading = false;
      state.bookingPageColor.value = action.payload?.value;
    });
    builder.addCase(putBookingPageColor.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(putBookingPageColor.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export default bookingPageColorState.reducer;
