import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {
  createRoutingForm, updateRoutingForm, listForms, createShareLinkRoutingForm, deleteRoutingForm, duplicateForm,
  createRoutingLogic,
  getLogic,
  updateRoutingLogic,
  deleteRoutingLogic,
  getFormDetails,
} from 'Api/ZCalendar';


const initialState = {
  forms: [],
  logic: {},
  isLoading: false,
  errorState: {
    isError: false,
    reason: '',
  },
  isEditingRoute: false,
};

export const createRoutingFormCalendar = createAsyncThunk(
  'routing/createRoutingForm',
  async ({data}, thunkAPI) => {
    const response = await createRoutingForm(data);
    return response;
  }
);

export const createShareLinkRoutingFormCalendar = createAsyncThunk(
  'routing/createShareLinkRoutingForm',
  async ({data, formId}, thunkAPI) => {
    const response = await createShareLinkRoutingForm(data, formId);
    return response;
  }
);

export const listFormsForCalendar = createAsyncThunk(
  'routing/listForms',
  async (thunkAPI) => {
    const response = await listForms();
    return response;
  }
);

export const updateRoutingFormCalendar = createAsyncThunk(
  'routing/updateRoutingForm',
  async ({data, formId}, thunkAPI) => {
    const response = await updateRoutingForm(formId, data);
    return response;
  }
);

export const getRoutingFormCalendar = createAsyncThunk(
  'routing/getRoutingForm',
  async ({formId}, thunkAPI) => {
    const response = getFormDetails(formId);
    return response;
  }
);

export const deleteRoutingFormCalendar = createAsyncThunk(
  'routing/deleteRoutingForm',
  (formId) => deleteRoutingForm(formId)
);

export const duplicateFormCalendar = createAsyncThunk(
  'routing/duplicateForm',
  async (formId, {dispatch}) => {
    await duplicateForm(formId);
    dispatch(listFormsForCalendar());
  }
);

export const createRoutingLogicCalendar = createAsyncThunk(
  'routing/createRoutingLogic',
  async ({data, formId}, thunkAPI) => {
    const response = await createRoutingLogic(formId, data);
    return response;
  }
);

export const getLogicCalendar = createAsyncThunk(
  'routing/getLogic',
  async (formId, thunkAPI) => {
    const response = await getLogic(formId);
    return response;
  }
);

export const updateRoutingLogicCalendar = createAsyncThunk(
  'routing/updateRoutingLogic',
  async ({data, formId}, thunkAPI) => {
    const response = await updateRoutingLogic(formId, data);
    return response;
  }
);

export const deleteRoutingLogicCalendar = createAsyncThunk(
  'routing/deleteRoutingLogic',
  (formId) => deleteRoutingLogic(formId)
);

export const routingStore = createSlice({
  name: 'RoutingStore',
  initialState,
  reducers: {
    addForm: (state, action) => {
      state.forms.push(action.payload);
    },
    setIsEditingRoute: (state, action) => {
      state.isEditingRoute = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listFormsForCalendar.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(listFormsForCalendar.fulfilled, (state, action) => {
      state.forms = action.payload.forms;
      state.isLoading = false;
    });
    builder.addCase(listFormsForCalendar.rejected, (state, action) => {
      state.errorState = {isError: true, error: 'Unable to list forms'};
      state.isLoading = false;
    });
    builder.addCase(createRoutingFormCalendar.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createRoutingFormCalendar.fulfilled, (state, action) => {
      state.forms.push(action.payload);
      state.isLoading = false;
    });
    builder.addCase(createRoutingFormCalendar.rejected, (state, action) => {
      state.errorState = {isError: true, error: 'Error creating form'};
      state.isLoading = false;
    });

    builder.addCase(getRoutingFormCalendar.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getRoutingFormCalendar.fulfilled, (state, action) => {
      const matchId = (element) => element.id === action.payload.id;
      const index = state.forms.findIndex(matchId);
      state.forms[index] = {...state.forms[index], ...action.payload};
      state.isLoading = false;
    });
    builder.addCase(getRoutingFormCalendar.rejected, (state, action) => {
      state.errorState = {isError: true, error: 'Error getting form details'};
      state.isLoading = false;
    });

    builder.addCase(createShareLinkRoutingFormCalendar.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createShareLinkRoutingFormCalendar.fulfilled, (state, action) => {
      const matchId = (element) => element.id === action.meta.arg.formId;
      const index = state.forms.findIndex(matchId);
      state.forms[index].link = action.payload.link;
      state.isLoading = false;
    });
    builder.addCase(createShareLinkRoutingFormCalendar.rejected, (state, action) => {
      state.errorState = {isError: true, error: 'Error creating publish link'};
      state.isLoading = false;
    });

    builder.addCase(updateRoutingFormCalendar.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateRoutingFormCalendar.fulfilled, (state, action) => {
      const matchId = (element) => element.id === action.payload.id;
      const index = state.forms.findIndex(matchId);
      // spreading the payload in order to preserve the canEdit key since the response does not include it.
      state.forms[index] = {...state.forms[index], ...action.payload};
      state.isLoading = false;
    });
    builder.addCase(updateRoutingFormCalendar.rejected, (state, action) => {
      state.errorState = {isError: true, error: 'Unable to update form'};
      state.isLoading = false;
    });
    builder.addCase(deleteRoutingFormCalendar.fulfilled, (state, action) => {
      const id = action.meta.arg;
      const index = state.forms.findIndex((form) => form.id === id);
      if (index !== -1) {
        state.forms.splice(index, 1);
      }
    });
    builder.addCase(duplicateFormCalendar.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(duplicateFormCalendar.rejected, (state, action, ) => {
      state.isLoading = false;
    });

    builder.addCase(createRoutingLogicCalendar.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createRoutingLogicCalendar.fulfilled, (state, action) => {
      const formId = action.meta.arg.formId;
      state.logic[formId] = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createRoutingLogicCalendar.rejected, (state, action) => {
      state.errorState = {isError: true, error: 'Error creating logic'};
      state.isLoading = false;
    });

    builder.addCase(getLogicCalendar.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getLogicCalendar.fulfilled, (state, action) => {
      const formId = action.meta.arg;
      state.logic[formId] = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getLogicCalendar.rejected, (state, action) => {
      state.errorState = {isError: true, error: 'Unable to get logic'};
      state.isLoading = false;
    });

    builder.addCase(updateRoutingLogicCalendar.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateRoutingLogicCalendar.fulfilled, (state, action) => {
      const formId = action.meta.arg.formId;
      state.logic[formId] = action.payload;
      state.isLoading = false;
    });
    builder.addCase(updateRoutingLogicCalendar.rejected, (state, action) => {
      state.errorState = {isError: true, error: 'Unable to update logic'};
      state.isLoading = false;
    });

    builder.addCase(deleteRoutingLogicCalendar.fulfilled, (state, action) => {
      const formId = action.meta.arg;
      delete state.logic[formId];
    });
  },
});

export const {setEditingRoute, setIsEditingRoute} = routingStore.actions;

export default routingStore.reducer;
