import React, {useEffect} from 'react';

import {isInIframe} from './integration';

// make modal position sync with the button which open up the modal
export const adjustModalPosition = async () => {
  // if not run in iframe, no need to adjust modal position
  if (!isInIframe()) {
    return;
  }

  setTimeout(() => {
    const popupList = document.querySelectorAll('.MuiPaper-root.MuiDialog-paper');
    const topLevelModal = popupList[popupList.length - 1];
    if (!topLevelModal) {
      return;
    }

    const rootList = document.querySelectorAll('.MuiDialog-root');
    const modalRoot = rootList[rootList.length - 1];
    if (!modalRoot) {
      return;
    }
    const modalRect = topLevelModal.getBoundingClientRect();

    // When modal height is bigger than 310, we leave it to the original built-in mechanism.
    if (modalRect.height > 310) return;
    const modalRootRect = modalRoot.getBoundingClientRect();

    const offsetLeft = (modalRootRect.width - modalRect.width) / 2;
    let offsetTop = (modalRootRect.height - modalRect.height) / 2;

    if (offsetTop > 200) {
      // When the modal height is small, we set the modal window distance to the bottom of iframe at 200px,
      // So that we see the modal instantly.
      offsetTop = modalRootRect.height - modalRect.height - 200;
    }

    topLevelModal.style.position = 'fixed';
    topLevelModal.style.top = `${offsetTop >= 0 ? offsetTop : 0}px`;
    topLevelModal.style.left = `${offsetLeft >= 0 ? offsetLeft : 0}px`;
    topLevelModal.style.margin = '0px';
  }, 20);
};

/**
 * HOC: adjust modal position to the center of the screen
 * @param {*} Component mui dialog component
 * @return {Component}
 */
export const withDialogPositionAdjust = (Component) => {
  return (props) => {
    const {disabledToggleScroll, ...others} = props;

    useEffect(() => {
      if (isInIframe() && !props.fullScreen && props.open) {
        adjustModalPosition();
      }
    }, [props.open, props.fullScreen, disabledToggleScroll]);

    return (<>
      <Component {...others}></Component>
    </>);
  };
};
