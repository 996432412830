import {HttpError} from 'Error/HttpError';
import {UnauthorizedError} from 'Error/UnauthorizedError';

export const defaultHeaders = {
  'Content-Type': 'application/json',
  'Accept': '*/*',
  'Access-Control-Allow-Origin': '*',
};

/**
 * Tries to get json body of response if it exists, returns undefined otherwise
 * @param {Response} resp
 * @return {Promise<?Object>}
 */
export const tryGetRespBody = async (resp) => {
  try {
    const body = await resp.json();
    return body;
  } catch (e) {}
  return undefined;
};

export const doFetch = async (url, method, data, headers = defaultHeaders) => {
  const response = await fetch(`/${url}`, {
    headers: new Headers(headers),
    method,
    body: (data instanceof ArrayBuffer)? data: JSON.stringify(data),
    redirect: 'follow',
  });
  if (response.status === 401) {
    // we have to handle UnauthorizedError by ourselves, call captcha again
    throw new UnauthorizedError(401);
  }
  return response;
};

export const fetchCall = async (
  url,
  method,
  data,
  headers = defaultHeaders,
) => {
  const response = await doFetch(url, method, data, headers);
  if (!response.ok) {
    throw new HttpError(response, await tryGetRespBody(response));
  } else {
    try {
      const reponseBody = await response.json();
      return reponseBody;
    } catch (error) {
      throw new Error(error, 'unable to parse json');
    }
  }
};

export const fetchCallWithErrorBody = async (
  url,
  method,
  data,
  headers = defaultHeaders,
) => {
  const response = await doFetch(url, method, data, headers);
  if (!response.ok) {
    throw new HttpError(response, await tryGetRespBody(response));
  } else {
    // if succeed, no reponse body return, just return an empty object
    return {};
  }
};
