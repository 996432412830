import {useState, useEffect, useCallback, useRef} from 'react';

import asyncStats from '@zoom/asynccomm-stats';
import {Base64} from 'js-base64';
import {debounce} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useLocation} from 'react-router-dom';

import {isSharedWithMe, isTeamAppt} from './apptAvailabilityUtils';
import {
  CLIENT_NOTIFICATIONS,
  LICENSE_INFO,
  SCOPE_TYPE,
  SUBMIT_FEEDBACK,
} from './consts';
import {getCookie} from './CookieUtil';
import {global as Bus} from './EventBus';
import ZoomSchedulerSDKClient from './zmschedulersdk';

import {patchIntegrationSettings} from 'Api/ZCalendar';
import {listAppointmentsForCalendar} from 'Store/HostAppointmentsStore';
import {listPendingForCalendar} from 'Store/HostBookingsStore';
import {validateUserConnections} from 'Store/HostConnectionHealthStore';
import {listWorkflowsForCalendar} from 'Store/HostWorkflowsStore';
import {
  actionHasRedirected,
  connectAccount,
  fetchUserHasLicense,
  listAccounts,
  updateFreeTrialInfo,
} from 'Store/IntegrationStore';
import {fetchManagedEvents} from 'Store/ManagedEventsStore';
import {snackbarActions} from 'Store/SnackbarStore';
import {
  compareSemVer,
  deferred,
  isBitSet,
  getVirtualUserEmail,
  isCciToken,
  isZoomieEmail,
  readJWTPayload,
  strEq,
} from 'Utils';
import {METRIC_NAME} from 'Utils/consts';

let token = (process.env.NODE_ENV === 'development') ?
  new URLSearchParams(window.location.search).get('tok') || '' :
  '';
export let parentURL = null;
export let shouldBuyLicense = false;
export let hasBillingEditPermission = true;

let lastCallTokenTime = new Date().getTime();
let isCciPage = false;
// request new token every hour
const DURATION = 3600 * 1000;
const schedulerDomain = window.location.origin || window.origin;

const searchParams = new URLSearchParams(window.location.search);
// injected by Web Team
export const canCreateZmail = (searchParams.get('setupZmail') || '') === 'true';
export const embedWidgetParams = searchParams.get('embedStyle') || '';

export const isInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    console.error(e);
    return true;
  }
};

export const isEuCookie = () => {
  const cluster = getCookie('zmail_cluster');
  try {
    if (cluster) {
      return cluster.toLowerCase().startsWith('ac70');
    }
  } catch {}
  return false;
};

// eslint-disable-next-line max-len
export const isMobile = navigator.userAgent.match(
  // eslint-disable-next-line max-len
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);

export const isMobileApp = window.location?.href?.includes('/m/');

export const isFromO365 = window.location?.href?.indexOf('clientType=calFor365') > 0;


const isEmbedMode = searchParams.get('embed') === 'true';
export const inWebPortal = isInIframe() && !isEmbedMode && !isMobileApp;
export const inZoomClient = !!window.__system_params__;
// Use the mock data in web and the style in client
export const styleInZoomClient = inZoomClient;
const initializeTokenRefresher = () => {
  setInterval(() => {
    const currentTime = new Date().getTime();
    if (currentTime - lastCallTokenTime > DURATION) {
      requestTokenFromPortal();
      asyncStats.report({metricName: METRIC_NAME.WEB_REFRESH_TOKEN, isImmediately: true});
    }
  }, 5000);
};
export const initializeToken = async (onComplete) => {
  const pathFirstPart = window.location.pathname.split('/')[1] || '';
  // user can use `${DOMAIN}/` or `${DOMAIN}/me/appts` to access the landing page entry
  const isLandingPageEntry = pathFirstPart === '' || pathFirstPart === 'me';
  const isLocalDevMode = window.location.host.startsWith('localhost');
  if (isFromO365 && typeof ZoomSchedulerSDKClient?.getSchedulerToken === 'function') {
    token = await ZoomSchedulerSDKClient.getSchedulerToken();
    if (token) {
      onComplete?.();
      return;
    }
  }
  if (!inWebPortal && !inZoomClient && (!isLandingPageEntry || isLocalDevMode)) {
    onComplete?.();
    return;
  }
  if (inZoomClient) {
    // only in Zoom Client, we need to dynamic import zoomSDK
    const {zoomSdk} = await import('Utils/JSBridge');
    window.zoomSdk = zoomSdk;
  }
  requestParentURL().then(() => {
    isCciPage = !!(parentURL?.href.includes('/appointment/zcc'));
    requestLicenseFromPortal().then((res) => {
      if (res.currentUserHasPermission === true || res.currentUserHasPermission === false) {
        hasBillingEditPermission = res.currentUserHasPermission;
      }
      // web will not issue a valid token for unlicensed user, so render purchase page directly
      if (res.trialing !== true && !isCciPage && !res.result) {
        shouldBuyLicense = true;
        onComplete?.();
        requestTokenFromPortal();
      } else {
        requestTokenFromPortal().then(() => {
          asyncStats.report({
            metricName: inZoomClient ? METRIC_NAME.CLIENT_LOGIN : METRIC_NAME.WEB_LOGIN,
            isImmediately: true,
          });
          onComplete?.();
          // Zoom Client has auto refresh notification, no need to poll
          if (!inZoomClient) {
            initializeTokenRefresher();
          }
        });
      }
    });
  });
};

const genericRequestFromPortal = (reqName, callbackName, params) => {
  const {promise, resolve} = deferred();
  // web portal callback to iframe
  const handler = (e) => {
    if (e.data.type === callbackName) {
      window.removeEventListener('message', handler);
      resolve(e.data);
    }
  };
  window.addEventListener('message', handler);
  // request to web portal
  window.parent.postMessage(
    {
      type: reqName,
      params,
    },
    '*'
  );
  return promise;
};

export const requestIframePosition = async (iframeId) => {
  try {
    if (inWebPortal) {
      const response = await genericRequestFromPortal('getIframePosition', 'iframePositionResult', {id: iframeId});
      return response.result;
    }
  } catch (error) {}
};

const requestTokenFromPortal = async () => {
  lastCallTokenTime = new Date().getTime();
  if (isFromO365 && typeof ZoomSchedulerSDKClient?.getSchedulerToken === 'function') {
    token = await ZoomSchedulerSDKClient.getSchedulerToken();
    if (token) {
      return token;
    }
  }
  if (inZoomClient) {
    const result = await window.zoomSdk.postJsMessage({
      functionName: 'calendarui_getzoombookingaccesstoken',
      params: {},
    });
    token = result.sak;
  } else if (!inWebPortal) {
    const host = window.location.host;
    let zoomDomain = '';
    if (host === 'scheduler.acqa.zoomdev.us') {
      zoomDomain = 'https://zoomdev.us';
    } else if (host === 'scheduler.zoom.us') {
      zoomDomain = 'https://zoom.us';
    }
    // only prod and acqa supports direct access, because `scheduler.zoom.us` is subdomain of `zoom.us`
    // and `scheduler.acqa.zoomdev.us`  is subdomain of `zoomdev.us`
    // if scheduler domain is not subdomain of web domain, web session can not be shared.
    if (!zoomDomain) {
      alert(`Current env doesn't support direct access, please use acqa env`);
      return '';
    }
    const res = await fetch('/appointment/token', {
      credentials: 'include',
    });
    const textResponse = await res.text();
    // should go to Zoom login page
    if (res.redirected) {
      window.location.href = `${zoomDomain}/signin?continue=${window.location}`;
    } else {
      try {
        token = JSON.parse(textResponse).result;
      } catch (error) {
        // if parse token response error, it means user has no license, should go to web portal Scheduler
        window.location.href = `${zoomDomain}/appointment`;
      }
    }
  } else {
    const response = await genericRequestFromPortal('getToken', 'tokenResult');
    token = response.result;
  }
  asyncStats.setToken({token: `Bearer ${token}`});
  return token;
};

export const requestParentURL = async () => {
  try {
    // Scheduler has parent URL only when loaded via iframe
    if (inWebPortal) {
      const response = await genericRequestFromPortal('getUrl', 'urlResult');
      parentURL = new URL(response.result);
    }
  } catch (error) {}

  if (parentURL?.searchParams.get('action') === 'homepage') {
    // if jump from marketing page, hide Zoom web top right buttons
    const elementsToBeHide = [
      '#btnScheduleMeeting',
      '#btnJoinMeeting',
      '#dropdown-hostmeeting',
      '#dropdown-joinmeeting',
      '#btnWhiteboards',
    ];
    elementsToBeHide.forEach((selector) =>
      adjustParentPageStyle(selector, 'display', 'none')
    );
  }
};

export const requestLicenseFromPortal = async () => {
  if (inZoomClient) {
    const clientVersion = window.__system_params__.version;
    // client free trial is supported since 6.0.10
    if (clientVersion && compareSemVer(clientVersion, '6.0.10') >= 0) {
      try {
        const res = await window.zoomSdk.postJsMessage({
          functionName: 'calendarui_getloginuserprofile',
          params: {modules: ['webSettings', 'zoomAccount']},
        });
        let trialing;
        // zoomSchedulerFreeTrialStatus:
        // -1 -> invalid status
        //  0 -> is in free trial period
        //  1 -> eligible to apply for a free trial
        if (res.webSettings.zoomSchedulerFreeTrialStatus >= 0) {
          trialing = !res.webSettings.zoomSchedulerFreeTrialStatus;
        }
        return {
          result: res.webSettings.isZoomSchedulerLicenseUser,
          freeTrialUrl: res.webSettings.zoomSchedulerTrialUrl,
          remainDays: res.webSettings.zoomSchedulerTrialRemainDays,
          trialing,
          isPaidAccount: res.zoomAccount.isPaidUser,
          enableFeedback: res.webSettings.enableFeedback,
        };
      } catch (error) {
        console.error('failed to get free trial info');
      }
    }
    return {result: true};
  }
  if (!inWebPortal) {
    return {result: true};
  }
  return genericRequestFromPortal('getLicense', 'licenseResult');
};

export const getToken = () => {
  if (shouldBuyLicense) {
    return;
  }
  // for Concat Center temporary test use (only on non-prod env)
  if (parentURL?.hostname.endsWith('zoomdev.us') && parentURL?.searchParams.get('tok')) {
    return parentURL.searchParams.get('tok');
  }
  const locationURL = new URL(window.location.href);
  if (window.location.hostname.endsWith('zoomdev.us') && locationURL.searchParams.get('tok')) {
    return locationURL.searchParams.get('tok');
  }
  return token || (process.env.NODE_ENV === 'development'? require('../mocks/testToken.json')?.token: '');
};

export const getAid = () => {
  try {
    const tokenPaylod = readJWTPayload(getToken());
    return tokenPaylod.aid;
  } catch (error) {}
};

/**
 * Get user info from the token or null if token invalid
 * @return {?SelfUserInfo}
 */
export const getUserInfo = () => {
  // if user doesn't have license, Web team will not issue token, no need to parse token payload
  if (shouldBuyLicense) {
    return {
      license: LICENSE_INFO.ZOOM_SCHEDULER,
      loginEmail: '',
      calendarId: '',
      isCciAccount: false,
      isDomainAdmin: false,
      isZoomie: false,
      zmail: '',
      userId: '',
      userName: '',
      accountId: '',
      paramsValid: true,
    };
  }
  try {
    const jwtPayload = readJWTPayload(getToken());
    if (!jwtPayload.eml && !jwtPayload.zmail) {
      console.error('missing token eml');
      return null;
    }
    const calendarId = formatCalendarId(jwtPayload);
    // [ZOOM-661401] support account admin assign admin privileges for Zoom Scheduler to a user
    const adminPrivilegeGranted = jwtPayload.ty === 8 && isBitSet(jwtPayload.perm_collection, 9);
    // token issued by web portal use "0/1/2" represent owner/admin/normal
    const isDomainAdmin = ['domainAdmin', 'domainOwner', '0', '1'].includes(jwtPayload.rol) || adminPrivilegeGranted;

    return {
      language: jwtPayload.locl,
      license: jwtPayload.lic,
      loginEmail: jwtPayload.eml,
      calendarId,
      isCciAccount: jwtPayload.ty === 10,
      isDomainAdmin,
      isZoomie: isZoomieEmail(jwtPayload.eml),
      zmail: jwtPayload.zmail,
      userId: jwtPayload.uid,
      userName: jwtPayload.un,
      accountId: jwtPayload.aid,
      paramsValid: true,
    };
  } catch (e) {
    console.error('could not parse token', e);
    Bus.emit('tokenExpired');
    return null;
  }
};

/**
 * Convert self info to the scope format
 * @param {SelfUserInfo} selfUserInfo
 * @return {Scope}
 */
export const selfToScope = (selfUserInfo) => {
  return {
    id: selfUserInfo?.userId,
    name: selfUserInfo?.userName,
    email: selfUserInfo?.loginEmail,
    type: SCOPE_TYPE.USER,
  };
};

export const isPhoneDevice = () => {
  // is mobile platform and has narrow screen < 480px (in order to exclude Pad)
  if (
    /mobile/i.test(navigator.userAgent) &&
    window.matchMedia('only screen and (max-width: 480px)').matches
  ) {
    return true;
  }
  return false;
};


export const useRedirect = () => {
  const hasActionRedirected = useSelector((state) => state.integrationState.hasActionRedirected);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const action = parentURL?.searchParams.get('action') || '';
  const eventIdFilter = parentURL?.searchParams.get('eventId') || '';
  const listAccountState = useSelector((state) => state.integrationState.loadingState.listAccountState);
  const haveNoAccount = useSelector((state) => state.integrationState.accounts.value.length === 0);

  const {pathname} = useLocation();
  useEffect(() => {
    dispatch(fetchUserHasLicense());
    // initially pull accounts to decide whether it's a new user or not (redirect callback doesn't need)
    !pathname.startsWith('/connect_callback') && dispatch(listAccounts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (isPhoneDevice()) {
      return navigate('/me/mobile', {replace: true});
    }
    // unlicensed user will not have token, so isCciToken() will not work
    if (shouldBuyLicense) {
      return navigate('/me/purchase', {replace: true});
    }
    if (!isCciToken() &&
      listAccountState === 'fulfilled' && haveNoAccount && !pathname.startsWith('/me/welcome')) {
      return navigate('/me/welcome');
    }
    // TODO no need to redirect anymore
    // // if in welcome page user has finished binding, should send user back to the original destination
    // if (!haveNoAccount && pathname.startsWith('/me/welcome')) {
    //   return navigate(-1);
    // }
    if (action.startsWith('settings-') && !hasActionRedirected) {
      // action should only works for the first route
      dispatch(actionHasRedirected());
      // support client jump to settings-profile / settings-account
      const dest = action.substring(action.indexOf('-')+1);
      return navigate(`/me/settings/${dest}`);
    }
    if (action === 'events' && !hasActionRedirected) {
      try {
        // action should only works for the first route
        dispatch(actionHasRedirected());
        return eventIdFilter ?
          navigate(`/me/events?eventId=${eventIdFilter}`) :
          navigate(`/me/events`);
      } catch (e) {
        console.error('could not parse token', e);
      }
    }
    if ((action === 'pollDetails' || action === 'pollBooking') && !hasActionRedirected) {
      const pendingEventId = parentURL?.searchParams.get('pendingEventId');
      const bookSpot = parentURL?.searchParams.get('bookSpot');
      if (pendingEventId && !pendingEventId.includes('/')) {
        if (action === 'pollBooking' && bookSpot) {
          navigate(`/me/polls/${pendingEventId}?bookSpot=${bookSpot}`, {replace: false});
        } else {
          navigate(`/me/polls/${pendingEventId}`, {replace: false});
        }
        dispatch(actionHasRedirected());
      }
    }
  }, [navigate, dispatch, action, hasActionRedirected, haveNoAccount,
    listAccountState, pathname, eventIdFilter]);
};

/**
 * get OAuth URL
 * @param {String} service 'google' or 'office365'
 * @return {String} oauthURL
 */
async function generateOAuthURL(service) {
  const response = await patchIntegrationSettings({
    id: 'connections',
    value: [{service}],
  });
  return response.data.oauthUrl;
}

export const useConnect3rdParty = () => {
  const [isFetchingOAuthURL, setIsFetchingOAuthURL] = useState(false);
  const dispatch = useDispatch();
  const goToAuth = useCallback(async (service) => {
    if (isFetchingOAuthURL) {
      return;
    }
    setIsFetchingOAuthURL(true);
    // Safari will block open popup window which not triggered by user click gesture,
    // so we need to open a new page in click handler synchronously, and assign href asynchronously.
    const winRef = window.open('', '_blank');
    winRef.document.title = 'Loading...';
    try {
      const url = await generateOAuthURL(service);
      winRef.location.href = url;
    } catch (error) {
      dispatch(snackbarActions.addSnackbar({
        message: 'integration.getOAuthURLFailed',
        severity: 'warning',
      }));
      winRef.close();
    } finally {
      setIsFetchingOAuthURL(false);
    }
  }, [dispatch, isFetchingOAuthURL]);

  return {
    isFetchingOAuthURL,
    goToAuth,
  };
};

export const useIframeAutoSize = (notAutoSize) => {
  const scrollableContainer = useRef(null);
  const adjustIframeSize = useCallback((height = '100%') => {
    if (notAutoSize) {
      height = '100%';
    }
    window.parent.postMessage(
      {
        type: 'adjustSize',
        height,
      },
      '*'
    );
  }, [notAutoSize]);

  useEffect(() => {
    // trigger resize immediately when props change
    if (notAutoSize) {
      adjustIframeSize();
    }
  }, [notAutoSize, adjustIframeSize]);

  useEffect(() => {
    let observer;
    let timer;
    if (scrollableContainer.current) {
      if (window.ResizeObserver) {
        observer = new ResizeObserver((entries) => {
          for (const entry of entries) {
            // Add white space to prevent the modal from exceeding the iframe area
            adjustIframeSize(`${entry.contentRect.height + 900}px`);
          }
        });
        observer.observe(scrollableContainer.current);
      } else {
        // if browser does not support resizeObserver then fallback to setInterval
        timer = setInterval(() => {
          adjustIframeSize(getComputedStyle(scrollableContainer.current).height);
        }, 1000);
      }
    }
    return () => {
      observer?.disconnect();
      timer && clearInterval(timer);
    };
  }, [adjustIframeSize]);
  return {
    scrollableContainer,
  };
};

// virtual user will not require user have a zmail account, so we don't user zmail as calendarId anymore
export const formatCalendarId = (jwtPayload) => {
  return getVirtualUserEmail(jwtPayload.uid);
};

// when user sign-out from zoom web portal, we shoud inform server revoke the token
export const useTokenRevoke = () => {
  useEffect(() => {
    window.addEventListener('unload', () => {
      if (!inWebPortal) {
        return;
      }
      const URL = '/zworkspace/admin/v1/auth/token';
      const tok = getToken();
      if (tok) {
        const payload = JSON.stringify({data: tok});
        fetch(URL, {method: 'POST', headers: {'Content-Type': 'application/json'}, body: payload, keepalive: true});
        navigator.sendBeacon(URL, new Blob([payload], {type: 'application/json'}));
      }
    });
  }, []);
};

export const adjustParentPageStyle = (selector, key, value, important = true) => {
  window.parent.postMessage(
    {
      type: 'adjustStyle',
      selector,
      key,
      value,
      important,
    },
    '*'
  );
};
export const useListenConnectAccountEvent = (onSuccess) => {
  const dispatch = useDispatch();
  const onConnectStatusChange = useCallback(
    async (event) => {
      if (event.key === 'connect_status') {
        const {meta} = await dispatch(
          connectAccount({
            callbackValue: event.newValue,
          })
        );
        if (meta.requestStatus === 'fulfilled') {
          onSuccess?.(JSON.parse(event.newValue));
        }
      }
    },
    [dispatch, onSuccess]
  );
  useEffect(() => {
    window.addEventListener('storage', onConnectStatusChange);
    return () => {
      window.removeEventListener('storage', onConnectStatusChange);
    };
  }, [onConnectStatusChange]);
};


// Return the vanityUrl if it is enabled and the vanityUrl is truthy, otherwise return the schedulerDomain
export const useSchedulerDomain = (withVanityURLPrefix) => {
  let {vanityUrl, vanityUrlEnabled} = useSelector((state) => state.organizationSettingState);
  if (vanityUrlEnabled && vanityUrl) {
    if (!vanityUrl.startsWith('https')) {
      vanityUrl = `https://${vanityUrl}`;
    }
    if (withVanityURLPrefix) {
      vanityUrl = `${vanityUrl}/zbook`;
    }
    return vanityUrl;
  }
  return schedulerDomain;
};

// generate short URL, type can be 'user' or 'appt' level
export const useCustomLink = (type, appt) => {
  const [customLink, setCustomLink] = useState('');
  const currentUserSlug = useSelector((state) => {
    if (state.hostSettingsState.slug.hasLoaded) {
      return state.hostSettingsState.slug.value;
    } else {
      return null;
    }
  });

  const domain = useSchedulerDomain(true);

  useEffect(() => {
    if (type === 'user' && currentUserSlug) {
      setCustomLink(`${domain}/${currentUserSlug}`);
    }
    if (type === 'appt' && appt) {
      if (appt.bookingLink) {
        if (appt.bookingLink.startsWith('http')) {
          setCustomLink(appt.bookingLink);
        } else {
          setCustomLink([domain, appt.bookingLink].join('/').replace('/zbook/zbook', '/zbook'));
        }
      } else if (isTeamAppt(appt)) {
        // Team Appt types will use the special appt.user slug 'd/<randomTeamSlug>'
        setCustomLink([domain, appt.user, appt.slug].join('/'));
      } else if (currentUserSlug && (appt.slug || appt.id) && !isSharedWithMe(appt)) {
        setCustomLink([domain, currentUserSlug, appt.slug || appt.id].join('/'));
      } else {
        // use the appt organizer's calendarId
        setCustomLink([domain, appt.organizer?.email, appt.slug || appt.id].join('/'));
      }
    }
  }, [appt, currentUserSlug, type, domain]);
  return customLink;
};

export const useInitClientNotification = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!inZoomClient) {
      return;
    }
    // the old and new zpns events will coexist in native client until 2024 Q2, so we may receive
    // one event twice at the same time, we can use debounce to ensure the callback to be executed only once.
    const DEBOUNCE_TIME = 200;
    Bus.on(CLIENT_NOTIFICATIONS.onAppointmentChanged, debounce(() => {
      const loc = window.location.pathname;
      if (loc.match('/appts')) {
        // Disable refreshing if admin is currently viewing another user's appts
        const search = new URLSearchParams(window.location.search);
        const userId = search.get('userId');
        const selfUser = getUserInfo();
        if (userId && strEq(userId, selfUser?.userId)) {
          return;
        }
      }
      dispatch(listAppointmentsForCalendar({
        query: {
          orderBy: 'updated',
        },
      }));
    }, DEBOUNCE_TIME));
    Bus.on(CLIENT_NOTIFICATIONS.onConnectionChanged, debounce(() => {
      dispatch(listAccounts());
      const {calendarId} = getUserInfo() || {};
      if (calendarId) {
        // re-query the current user's connection health to clear error banner
        dispatch(validateUserConnections([calendarId.toLowerCase()]));
      }
    }, DEBOUNCE_TIME));
    const onZMailAccountChange = async () => {
      // token will be invalidated when zmail account change, need to request a new one
      await forceRefreshClientToken({
        zmailChange: true,
      });
      dispatch(listAccounts());
    };
    Bus.on(CLIENT_NOTIFICATIONS.onZMailAccountChange, onZMailAccountChange);
    Bus.on(CLIENT_NOTIFICATIONS.onZMailAccountChangeNew, onZMailAccountChange);
    Bus.on(CLIENT_NOTIFICATIONS.onWorkflowChange, debounce(() => {
      dispatch(listWorkflowsForCalendar({}));
    }, DEBOUNCE_TIME));
    Bus.on('refreshToken', ({target}) => {
      token = target.info.sak;
      asyncStats.setToken({token: `Bearer ${token}`});
      asyncStats.report({metricName: METRIC_NAME.CLIENT_REFRESH_TOKEN, isImmediately: true});
    });
    Bus.on(CLIENT_NOTIFICATIONS.onManagedAppointmentChange, debounce(() => {
      dispatch(fetchManagedEvents());
    }, DEBOUNCE_TIME));
    Bus.on(CLIENT_NOTIFICATIONS.onPendingEventChange, debounce(() => {
      dispatch(listPendingForCalendar({}));
    }, DEBOUNCE_TIME));
    Bus.on(CLIENT_NOTIFICATIONS.onFreeTrialInfoChange, ({target}) => {
      dispatch(updateFreeTrialInfo({
        url: target.result.info.zoomSchedulerTrialUrl,
        remainDays: target.result.info.zoomSchedulerTrialRemainDays,
        status: target.result.info.zoomSchedulerFreeTrialStatus,
      }));
    });
    // client transparently forwards all server events to frontend, subsequent new event doesn't require client support
    Bus.on(CLIENT_NOTIFICATIONS.schedulerGenericZPNS, ({target}) => {
      let payload = {};
      try {
        payload = JSON.parse(Base64.decode(target.result.data));
      } catch (error) {
        console.warn('decode zpns data failed');
      }
      switch (payload.type) {
        case 'appointment':
          Bus.emit(CLIENT_NOTIFICATIONS.onAppointmentChanged);
          break;
        case 'setting':
          if (payload.id === 'connections') {
            Bus.emit(CLIENT_NOTIFICATIONS.onConnectionChanged);
          }
          break;
        case 'workflow':
          Bus.emit(CLIENT_NOTIFICATIONS.onWorkflowChange);
          break;
        case 'managedAppointment':
          Bus.emit(CLIENT_NOTIFICATIONS.onManagedAppointmentChange);
          break;
        case 'pendingEvent':
          Bus.emit(CLIENT_NOTIFICATIONS.onPendingEventChange);
          break;
        case 'schedulerGroup':
          Bus.emit(CLIENT_NOTIFICATIONS.onGroupChange);
          break;
        default:
          break;
      }
    });
  }, [dispatch]);
};

export const navigateParentPage = (url) => {
  window.parent.postMessage(
    {
      type: 'navigateTo',
      url,
    },
    '*'
  );
};

export const isTokenExpired = () => {
  try {
    const jwtPayload = readJWTPayload(getToken());
    // Date.now and jwt.exp both use the time elapsed since Jan 1, 1970 00:00:00 UTC. so there is no timezone issue.
    if (Date.now() >= jwtPayload.exp * 1000) {
      return true;
    }
  } catch (error) {}
  return false;
};

// common layer will fetch new token from server
export const forceRefreshClientToken = async (params = {}) => {
  try {
    const res = await window.zoomSdk.postJsMessage({
      functionName: 'calendarui_refreshzoombookingaccesstoken',
      params,
    });
    if (res.sak) {
      token = res.sak;
      asyncStats.setToken({token: `Bearer ${token}`});
      asyncStats.report({metricName: METRIC_NAME.CLIENT_REFRESH_TOKEN, isImmediately: true});
    }
  } catch (e) {
    console.error('force refresh token failed', e);
  }
};

export const openFeedbackDialog = async () => {
  if (styleInZoomClient) {
    window.zoomSdk.postJsMessage({
      functionName: 'calendarui_openfeedback',
      params: {product: 'scheduler'},
    });
    return;
  }
  const res = await genericRequestFromPortal('showFeedbackDialog', 'feedbackResult');
  if (!res.result) {
    // this is a fallback if dialog open failed
    window.open(SUBMIT_FEEDBACK, '_blank');
  }
};

export const openNewPage = (url) => {
  if (isFromO365 && typeof ZoomSchedulerSDKClient?.openDialog === 'function') {
    ZoomSchedulerSDKClient.openDialog(url);
    return;
  }
  window.open(url, '_blank');
};
