import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

import DEFallback from './locales/de-DE/fallback.json';
import DE from './locales/de-DE/translation.json';
import ENFallback from './locales/en-US/fallback.json';
import EN from './locales/en-US/translation.json';
import ESFallback from './locales/es-ES/fallback.json';
import ES from './locales/es-ES/translation.json';
import FRFallback from './locales/fr-FR/fallback.json';
import FR from './locales/fr-FR/translation.json';
import ITFallback from './locales/it-IT/fallback.json';
import IT from './locales/it-IT/translation.json';
import JAFallback from './locales/ja-JP/fallback.json';
import JA from './locales/ja-JP/translation.json';
import KOFallback from './locales/ko-KR/fallback.json';
import KO from './locales/ko-KR/translation.json';
import NLFallback from './locales/nl-NL/fallback.json';
import NL from './locales/nl-NL/translation.json';
import PTFallback from './locales/pt-BR/fallback.json';
import PT from './locales/pt-BR/translation.json';
import CNFallback from './locales/zh-CN/fallback.json';
import CN from './locales/zh-CN/translation.json';
import TWFallback from './locales/zh-TW/fallback.json';
import TW from './locales/zh-TW/translation.json';

let allLocales;
import('date-fns/locale').then((locales) => {
  allLocales = locales;
});

export const getLocale = (localeString) => {
  const locale = localeString.replace('-', '');
  const rootLocale = locale.substring(0, 2); // language code without region code, e.g. en in en-US

  const fallbackMapping = { // Currently there is no 'en', 'zh', or 'fa' in the date-fns locale list
    en: 'enUS', // en default to English (United States) when region code not specified
    zh: 'zhCN', // zh default to Chinese (Simplified, China) when region code not specified
    fa: 'faIR', // fa default to Persian (Iran) when region code not specified
  };

  return allLocales[locale] || allLocales[rootLocale] || allLocales[fallbackMapping?.[rootLocale] || 'enUS'];
};

export const i18nextInst = i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      // default order is ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag']
      order: ['querystring', 'navigator', 'htmlTag'],
      // We can use this query string 'lng' to override language settings for local testing
      // e.g. if we want to test Simplified Chinese on localhost, just add ?lng=zh-CN to the url
      // lookupQuerystring: 'lng', // default value is 'lng'
    },
    resources: {
      'de': {
        translation: DE,
        fallback: DEFallback,
      },
      'en': {
        translation: EN,
        fallback: ENFallback,
      },
      'es': {
        translation: ES,
        fallback: ESFallback,
      },
      'fr': {
        translation: FR,
        fallback: FRFallback,
      },
      'it': {
        translation: IT,
        fallback: ITFallback,
      },
      'ja': {
        translation: JA,
        fallback: JAFallback,
      },
      'ko': {
        translation: KO,
        fallback: KOFallback,
      },
      'nl': {
        translation: NL,
        fallback: NLFallback,
      },
      'pt': {
        translation: PT,
        fallback: PTFallback,
      },
      'zh': { // fallback to Simplified Chinese (zh-CN) for Chinese by default
        translation: CN,
        fallback: CNFallback,
      },
      'zh-TW': {
        translation: TW,
        fallback: TWFallback,
      },
      // TODO: remove the language flavor hack when i18next supports fallbackNS properly
      // i18next supports specifying language flavor in this format
      // e.g. en-INFORMAL is a flavor of en (English)
      'de-FALLBACK': {
        translation: DEFallback,
      },
      'en-FALLBACK': {
        translation: ENFallback,
      },
      'es-FALLBACK': {
        translation: ESFallback,
      },
      'fr-FALLBACK': {
        translation: FRFallback,
      },
      'it-FALLBACK': {
        translation: ITFallback,
      },
      'ja-FALLBACK': {
        translation: JAFallback,
      },
      'ko-FALLBACK': {
        translation: KOFallback,
      },
      'nl-FALLBACK': {
        translation: NLFallback,
      },
      'pt-FALLBACK': {
        translation: PTFallback,
      },
      'cn-FALLBACK': {
        translation: CNFallback,
      },
      'tw-FALLBACK': {
        translation: TWFallback,
      },
    },
    // debug: true, // Enable debug to print out more infomation

    // lng: 'en', // remove this when multi-lang supported
    // fallbackLng: 'en', // default fall back language is 'dev'
    fallbackLng: {
      'de': ['de-FALLBACK', 'en'],
      // 'en': ['en-FALLBACK'], // Default is set to en, not needed for now
      'es': ['es-FALLBACK', 'en'],
      'fr': ['fr-FALLBACK', 'en'],
      'it': ['it-FALLBACK', 'en'],
      'ja': ['ja-FALLBACK', 'en'],
      'ko': ['ko-FALLBACK', 'en'],
      'nl': ['nl-FALLBACK', 'en'],
      'pt': ['pt-FALLBACK', 'en'],
      'zh': ['cn-FALLBACK', 'en'],
      'zh-TW': ['tw-FALLBACK', 'en'],
      'default': ['en', 'en-FALLBACK'],
      // After investigation and discussion with the web team, we learned that there is no such language code as jp-JP
      // The correct code should be ja or ja-JP https://localizely.com/locale-code/ja-JP/
      // But the web team used the wrong code from the very beginning and it’s hard to correct now due to dependencies
      // They will try to find a solution when they have bandwidth. For now, we have to use this temporary fix
      'jp': ['ja', 'ja-FALLBACK', 'en'],
    },

    // TODO: use fallback namespace when it's properly supported, remove the language flavor hack above
    //   Fallback namespace doesn't work properly with fallback lanuage at the moment
    //   i18next will try to load the fallback language first before loading the fallback namespace
    //   e.g. if we have zh/translation.json zh/fallback.json en/translation.json
    //   the order we want to load them is:
    //     zh/translation.json -> zh/fallback.json -> en/translation.json
    //   but right now if we enable both fallbackLng and fallbackNS, the order is:
    //     zh/translation.json -> en/translation.json -> zh/fallback.json
    ns: ['translation', 'fallback'], // default value is 'translation'
    // defaultNS: 'translation', // default namespace is 'translation'
    // fallbackNS: 'fallback', // default value is false

    // nsSeparator: ':', // default namespace separator is ':'
    // keySeparator: '.', // default key separator is '.'
    interpolation: {
      escapeValue: false,
      // prefix: '{{', // default prefix for interpolation is '{{'
      // suffix: '}}', // default suffix for interpolation is '}}'
    },
  });

// This can be use for testing
// i18next.changeLanguage(navigator.language); // Change language based on browser setting

export const getI18nLanguage = () => {
  return i18next.resolvedLanguage;
};

export const getCurrentLocale = () => {
  return getLocale(getI18nLanguage());
};

export default i18next;
