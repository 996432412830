import {createAsyncThunk, createSlice, miniSerializeError} from '@reduxjs/toolkit';
import chunk from 'lodash/chunk';

import {
  bookPoll,
  cloneAppointment,
  deleteAppointment,
  listAppointments,
  patchAppointment,
  getApptDetail,
} from 'Api/ZCalendar';
import {HttpError} from 'Error/HttpError';
import {validateUserConnections} from 'Store/HostConnectionHealthStore';
import {isTeamAppt} from 'Utils/apptAvailabilityUtils';
import {
  ADHOC_TYPE,
  APPT_FORM_MODE,
  APPT_FORM_TYPE,
  APPT_TYPE,
  CAPACITY_TYPE,
  EVENT_STATUS,
  SCOPE_TYPE,
} from 'Utils/consts';
import {getUserInfo} from 'Utils/integration';

const apptFilterInitialValue = {
  owners: [],
  creators: [],
  hosts: [],
  pageStatus: '',
  permission: '',
};

const initialState = {
  latestRequestId: null,
  appointments: [],
  expiredAppts: {
    list: null,
    isLoading: false,
    errorState: {
      isError: false,
      reason: '',
    },
  },
  isLoading: false,
  errorState: {
    isError: false,
    reason: '',
  },
  cloneId: '',
  /** @type {OrgUser} */
  selectHostUser: {}, // admin user can create appointment for anyone
  forms: {
    // APPT_FORM_TYPE enum or null to represent the currently open form
    openFormType: null,
    // store metadata about the adhoc form, which subvariant, edit / create, etc.
    adhocFormType: {
      adhocType: ADHOC_TYPE.ONE_OFF,
    },
    // store metadata about the appt form, which subvariant, edit / create, etc.
    standardFormType: {
      apptType: APPT_TYPE.RECURRING,
      capacityType: CAPACITY_TYPE.ONE,
      mode: APPT_FORM_MODE.CREATE,
      appointment: undefined,
      calendarId: '',
    },
  },
  filter: {
    ...apptFilterInitialValue,
    search: '',
  },
  hasLoaded: false,

  selectHostTeam: null, // The team which can join appt
};

export const selectApptFilterCount = (state) => {
  const apptFilter = state.hostAppointmentsState.filter;
  let count = 0;
  count += !!(apptFilter.owners.length);
  count += !!(apptFilter.creators.length);
  count += !!(apptFilter.hosts.length);
  count += (apptFilter.pageStatus !== '');
  count += (apptFilter.permission !== '');
  return count;
};


export const listAppointmentsForCalendar = createAsyncThunk(
  'hostAppointments/listByCalendar',
  async ({asScope, query, validateHostConnections}, thunkAPI) => {
    const response = await listAppointments(asScope, query);
    if (validateHostConnections) {
      /** @type {Appt[]} */
      const teamAppts = response.items.filter((appt) => {
        return isTeamAppt(appt);
      });
      const allUniqueHosts = new Set();
      const selfCalEmail = getUserInfo()?.calendarId?.toLowerCase();
      if (selfCalEmail) {
        allUniqueHosts.add(selfCalEmail);
      }
      teamAppts.forEach((appt) => {
        const apptHosts = appt.attendees;
        apptHosts.forEach((host) => {
          allUniqueHosts.add(host.email.toLowerCase());
        });
      });
      const hostEmails = Array.from(allUniqueHosts);
      const state = thunkAPI.getState();
      const unvalidatedHostEmails = hostEmails.filter((hostEmail) => {
        return !state.hostConnectionHealthState.healthResults[hostEmail];
      });
      const VALIDATE_BATCH_SIZE = 20;
      const chunkedHostEmails = chunk(unvalidatedHostEmails, VALIDATE_BATCH_SIZE);
      chunkedHostEmails.forEach((emailBatch) => {
        thunkAPI.dispatch(validateUserConnections(emailBatch));
      });
    }
    return response;
  },
  {
    serializeError: (e) => {
      if (e instanceof HttpError) {
        return {
          ...miniSerializeError(e),
          body: e.body,
          errorCode: e.errorCode,
        };
      }
      return miniSerializeError(e);
    },
  }
);

export const listExpiredApptsForCalendar = createAsyncThunk(
  'hostAppointments/listExpiredByCalendar',
  async ({calendarId, query}, thunkAPI) => {
    const response = await listAppointments(calendarId, query);
    return response;
  }
);

export const getAppointmentForCalendar = createAsyncThunk(
  'hostAppointments/getAppointment',
  async ({apptId, asScope}, thunkAPI) => {
    const response = await getApptDetail(asScope, apptId);
    return response;
  }
);

export const deleteAppointmentForCalendar = createAsyncThunk(
  'hostAppointments/deleteAppointment',
  async ({apptId, asScope, calId}, thunkAPI) => {
    const response = await deleteAppointment(asScope, apptId);
    return response;
  }
);

export const cloneAppointmentForCalendar = createAsyncThunk(
  'hostAppointments/cloneAppointment',
  async ({apptId, calId}, thunkAPI) => {
    const response = await cloneAppointment(apptId, calId);
    return response;
  }
);

export const editAppointmentForCalendar = createAsyncThunk(
  'hostAppointments/editAppointment',
  async ({apptId, asScope, data}, thunkAPI) => {
    const response = await patchAppointment(apptId, asScope, data);
    return response;
  },
  {
    serializeError: (e) => {
      if (e instanceof HttpError) {
        return {
          ...miniSerializeError(e),
          body: e.body,
          errorCode: e.errorCode,
        };
      }
      return miniSerializeError(e);
    },
  }
);

export const bookPollForCalendar = createAsyncThunk(
  'hostAppointments/bookPoll',
  async ({calId, data, pendingEventId}, thunkAPI) => {
    const response = await bookPoll(data, pendingEventId, calId);
    return response;
  }
);

export const hostAppointmentsStore = createSlice({
  name: 'HostAppointmentsStore',
  initialState,
  reducers: {
    addAppointment: (state, action) => {
      state.appointments.push(action.payload);
    },
    removeAppointment: (state, action) => {
      state.appointments = state.appointments.filter((appt) => action.payload !== appt.id);
    },
    setHostUser(state, action) {
      state.selectHostUser = action.payload;
    },
    setHostTeam(state, action) {
      state.selectHostTeam = action.payload;
    },
    openCreateMeetingPollForm(state, action) {
      state.forms.openFormType = APPT_FORM_TYPE.ADHOC;
      state.forms.adhocFormType = {
        adhocType: APPT_TYPE.POLL,
      };
    },
    /**
     *
     * @param {*} state
     * @param {Object} action
     * @param {Appt} action.payload
     */
    openEditMeetingPollForm(state, action) {
      state.forms.openFormType = APPT_FORM_TYPE.ADHOC;
      state.forms.adhocFormType = {
        editingAdhoc: action.payload,
        adhocType: APPT_TYPE.POLL,
      };
    },
    openCreateOneOffApptForm(state, action) {
      state.forms.openFormType = APPT_FORM_TYPE.ADHOC;
      state.forms.adhocFormType = {
        adhocType: APPT_TYPE.ONE_OFF,
      };
    },
    openApptTypeForm(state, action) {
      state.forms.openFormType = APPT_FORM_TYPE.APPT_TYPE_FORM;
    },
    openStandardApptForm(state, action) {
      /** @type {Scope} */
      const asScope = action.payload.asScope;
      if (asScope?.type === SCOPE_TYPE.TEAM) {
        const self = getUserInfo();
        state.selectHostUser = {
          userId: self.userId,
          name: self.userName,
          email: self.loginEmail,
        };
        state.selectHostTeam = {...asScope};
      } else if (!action.payload.asScope?.id) {
        const self = getUserInfo();
        state.selectHostUser = {
          userId: self.userId,
          name: self.userName,
          email: self.loginEmail,
        };
        state.selectHostTeam = null;
      } else if (action.payload.asScope?.type === SCOPE_TYPE.USER) {
        state.selectHostUser = {
          userId: asScope.id,
          name: asScope.name,
          email: asScope.email,
        };
        state.selectHostTeam = null;
      }
      state.forms.openFormType = APPT_FORM_TYPE.STANDARD;
      state.forms.standardFormType = {
        apptType: action.payload.apptType,
        calendarId: action.payload.calendarId,
        capacityType: action.payload.capacityType,
        appointment: action.payload.appointment,
        isManagedEvent: !!action.payload.isManagedEvent,
        mode: !!action.payload.appointment ? APPT_FORM_MODE.EDIT : APPT_FORM_MODE.CREATE,
      };
    },
    closeApptForm(state, action) {
      state.forms.openFormType = null;
    },
    setApptFilter(state, action) {
      state.filter = {...state.filter, ...action.payload};
    },
    clearApptFilter(state, action) {
      state.filter = {...apptFilterInitialValue, search: state.filter.search};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listAppointmentsForCalendar.fulfilled, (state, action) => {
      if (action.meta.requestId === state.latestRequestId) {
        state.isLoading = false;
        state.hasLoaded = true;
        state.appointments = action.payload.items.filter((appt) => appt.status !== EVENT_STATUS.CANCELLED);
        state.email = action.payload.summary;
        state.errorState = {
          isError: false,
          reason: '',
        };
      }
    });
    builder.addCase(listAppointmentsForCalendar.pending, (state, action) => {
      state.latestRequestId = action.meta.requestId;
      state.isLoading = true;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listAppointmentsForCalendar.rejected, (state, action) => {
      if (action.meta.requestId === state.latestRequestId) {
        state.isLoading = false;
        state.hasLoaded = true;
        let reason = 'storeErrors.listAppointmentsForCalendar';
        if (action.error?.errorCode === 403) {
          reason = 'storeErrors.forbidden403Error';
        }
        state.errorState = {
          isError: true,
          reason: reason,
        };
      }
    });

    builder.addCase(listExpiredApptsForCalendar.fulfilled, (state, action) => {
      state.expiredAppts.isLoading = false;
      state.expiredAppts.list = action.payload.items.filter((appt) => appt.status !== EVENT_STATUS.CANCELLED);
      state.expiredAppts.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listExpiredApptsForCalendar.pending, (state, action) => {
      state.expiredAppts.isLoading = true;
      state.expiredAppts.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listExpiredApptsForCalendar.rejected, (state, action) => {
      state.expiredAppts.isLoading = false;
      state.expiredAppts.errorState = {
        isError: true,
        reason: 'storeErrors.listExpiredAppointmentsForCalendar',
      };
    });

    builder.addCase(deleteAppointmentForCalendar.fulfilled, (state, action) => {
      state.appointments = state.appointments.filter((appt) => action.meta.arg.apptId !== appt.id);
    });

    builder.addCase(editAppointmentForCalendar.fulfilled, (state, action) => {
      const matchId = (element) => element.id === action.payload.id;
      const index = state.appointments.findIndex(matchId);
      state.appointments[index] = action.payload;
    });

    builder.addCase(getAppointmentForCalendar.fulfilled, (state, action) => {
      const matchId = (element) => element.id === action.payload.id;
      const index = state.appointments.findIndex(matchId);
      state.appointments[index] = action.payload;
    });

    builder.addCase(cloneAppointmentForCalendar.pending, (state, action) => {
      state.cloneId = action.meta.arg.apptId;
    });

    builder.addCase(cloneAppointmentForCalendar.fulfilled, (state, action) => {
      state.cloneId = '';
      state.appointments = [...state.appointments, action.payload];
    });

    builder.addCase(cloneAppointmentForCalendar.rejected, (state, action) => {
      state.cloneId = '';
    });
  },
});

export const {
  addAppointment,
  clearApptFilter,
  closeApptForm,
  openApptTypeForm,
  openCreateMeetingPollForm,
  openCreateOneOffApptForm,
  openEditMeetingPollForm,
  openStandardApptForm,
  removeAppointment,
  setApptFilter,
  setHostTeam,
  setHostUser,
} = hostAppointmentsStore.actions;

export default hostAppointmentsStore.reducer;
