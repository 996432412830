import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {uniqBy} from 'lodash';

import {
  getGroups,
  createGroup,
  patchGroup,
  deleteGroup,
  getGroupMembers,
  addGroupMember,
  deleteGroupMember,
  validateHostConnections,
} from 'Api/ZCalendar';
import {getVirtualUserEmail, strEq} from 'Utils';
import {ACCESS_ROLES, USER_ACCESS_TYPE} from 'Utils/consts';
import {getUserInfo} from 'Utils/integration';

export const groupSelectors = {
  selectSelfIsGroupAdmin: (state) => {
    const userId = getUserInfo()?.userId;
    const isGroupAdmin = (state.groupState.groups || []).some(
      (group) => (group.admins || []).some(
        (adminUser) => adminUser.id === userId
      )
    );
    return isGroupAdmin;
  },
  selectSelfIsGroupAdminForUser: (state, userCalId) => {
    const isGroupAdmin = Object.values(state.groupState.groupMemberMapping).some((groupMembers) => {
      return groupMembers.some((member) => strEq(getVirtualUserEmail(member.userId), userCalId));
    });
    return isGroupAdmin;
  },
  selectUserAccessType: (state, appt) => {
    const isDomainAdmin = getUserInfo()?.isDomainAdmin;
    if (isDomainAdmin) {
      return USER_ACCESS_TYPE.ADMIN;
    }
    const selfIsGroupAdmin = groupSelectors.selectSelfIsGroupAdmin(state);
    const apptOwnerCal = (
      appt?.attendees?.find((user) => user.accessRole === ACCESS_ROLES.owner) ??
      appt.organizer
    )?.email;
    if (selfIsGroupAdmin && groupSelectors.selectSelfIsGroupAdminForUser(state, apptOwnerCal)) {
      return USER_ACCESS_TYPE.GROUP_ADMIN;
    }
    return USER_ACCESS_TYPE.NORMAL;
  },
};

export const addGroup = createAsyncThunk(
  'groupStore/addGroup',
  async (data) => {
    const response = await createGroup(data);
    return response;
  }
);

export const editGroup = createAsyncThunk(
  'groupStore/editGroup',
  async (group) => {
    const response = await patchGroup(group);
    return response;
  }
);

export const removeGroup = createAsyncThunk(
  'groupStore/removeGroup',
  async (groupId) => {
    const response = await deleteGroup(groupId);
    return response;
  }
);

export const listGroups = createAsyncThunk(
  'groupStore/listGroups',
  async (args, thunkAPI) => {
    const response = await getGroups(args?.query);
    return response;
  }
);

export const listGroupMembers = createAsyncThunk(
  'groupStore/listGroupMembers',
  async (data, thunkAPI) => {
    const groupId = data.id;
    const response = await getGroupMembers(groupId);
    return response;
  }
);

export const assignAsMember = createAsyncThunk(
  'groupStore/assignAsMember',
  async (data) => {
    const response = await addGroupMember(data);
    return response;
  }
);

export const removeAsMember = createAsyncThunk(
  'groupStore/removeAsMember',
  async (data) => {
    const response = await deleteGroupMember(data);
    return response;
  }
);

export const assignAsAdmin = createAsyncThunk(
  'groupStore/assignAsAdmin',
  async (data) => {
    const response = await patchGroup({
      ...data.group,
      admins: [...data.group.admins, {id: data.member.userId, name: data.member.name}],
    });
    return response;
  }
);

export const removeAsAdmin = createAsyncThunk(
  'groupStore/removeAsAdmin',
  async (data) => {
    const response = await patchGroup({
      ...data.group,
      admins: data.group.admins.filter((admin) => admin.id !== data.member.id),
    });
    return response;
  }
);

export const validateUserConnections = createAsyncThunk(
  'groupStore/validationConnections',
  async (emails, thunkAPI) => {
    const response = await validateHostConnections(emails);
    return response;
  }
);

const mergeAdminAndMember = (adminList, memberList) => {
  const members = memberList.map((member)=> ({
    isMember: true,
    ...member,
    id: member.userId,
    isGroupAdmin: !!(adminList?.find((admin) => admin.id === member.userId)),
  }));
  const admins = adminList?.map((admin) => ({
    isMember: false,
    ...admin,
    isGroupAdmin: true,
  })) || [];
  const uniqMembers = uniqBy([...members, ...admins], 'id').sort((a, b) => a.name.localeCompare(b.name));
  return uniqMembers;
};

export const groupState = createSlice({
  name: 'groupStore',
  initialState: {
    groups: [],
    members: [],
    groupMemberMapping: {},
    loading: false,
  },
  reducers: {
    setLoadingFalse: (state, action) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listGroupMembers.pending, (state, action) => {
      state.loading = true;
      state.members = [];
    });
    builder.addCase(listGroupMembers.fulfilled, (state, action) => {
      const selectedGroup = state.groups.find((group) => group.id === action.meta.arg.id);
      state.groupMemberMapping[selectedGroup.id] = action.payload.members;
      const uniqMembers = mergeAdminAndMember(selectedGroup.admins, action.payload.members);
      state.members = uniqMembers;
      selectedGroup.memberCount = uniqMembers.filter((user) => user.isMember).length;
      state.loading = false;
    });
    builder.addCase(listGroupMembers.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(listGroups.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(listGroups.fulfilled, (state, action) => {
      state.groups = action.payload.groups;
      if (action.meta.arg?.id) {
        const selectedGroup = state.groups.find((group) => group.id === action.meta.arg.id);
        const uniqMembers = mergeAdminAndMember(selectedGroup.admins, state.groupMemberMapping[selectedGroup.id]);
        state.members = uniqMembers;
        selectedGroup.memberCount = uniqMembers.filter((user) => user.isMember).length;
      }
      state.loading = false;
    });
    builder.addCase(listGroups.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addGroup.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addGroup.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addGroup.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editGroup.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(editGroup.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(editGroup.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeGroup.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeGroup.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeGroup.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(assignAsMember.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(assignAsMember.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(assignAsMember.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeAsMember.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeAsMember.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeAsMember.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(assignAsAdmin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(assignAsAdmin.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(assignAsAdmin.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeAsAdmin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeAsAdmin.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(removeAsAdmin.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(validateUserConnections.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(validateUserConnections.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const groupStateActions = groupState.actions;
export default groupState.reducer;
