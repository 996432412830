import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {validateUserConnections} from './HostConnectionHealthStore';

import {
  getIntegrationSettings,
  patchIntegrationSettings,
  sendConnectCalendarEmail,
} from 'Api/ZCalendar';
import {FREE_TRIAL_CONDITION, GET_CONN_ERROR, MS_MAX_PULL_CALS, PROVIDER_TYPE, ZOOM_WEB_DOMAIN} from 'Utils/consts';
import {getUserInfo, isEuCookie, requestLicenseFromPortal} from 'Utils/integration';

const {APPLE, GOOGLE, MS, ZOOM} = PROVIDER_TYPE;

export const NWS_ERROR_CODE = {
  OAUTH_TOKEN_NOT_EXIST: '30093005',
  INVALID_GRANT: '30097503',
  LACK_OF_PERMISSION: '30091027', // user didn't select required permissions
};
const MAX_3RD_PARTY_ACCOUNTS = 5;
const initialState = {
  loadingState: {
    isLoading: false,
    listAccountState: false,
  },
  errorState: {
    isError: false,
    reason: '',
  },
  // set to true when first get connection request ended
  accountsInitiated: false,
  accounts: {
    id: 'connections',
    value: [],
  },
  // Map from account.email to last-fetched calendarList
  accountCalendars: {
    mapping: {},
    hasLoaded: false,
  },
  userHasLicense: true,
  isPaidAccount: false,
  accountHasLicense: true,
  hasActionRedirected: false,
  freeTrialInfo: {
    url: '',
    remainDays: 0,
    status: undefined,
  },
  enableFeedback: true,
  notifyHostLoading: '',
  webPortalLanguage: '',
};

/**
 * Remove no longer valid pullCalendarIds to prevent calendar validation errors
 * @param {Account[]} accounts
 * @param {Object<string, ServiceCalendar[]>} calendarsList
 * @return {Account[]}
 */
export const cleanPullCalendarsData = (accounts, calendarsList) => {
  return accounts.map((acc) => {
    const accountCals = calendarsList[acc.email] || [];
    const accountCalIds = accountCals.map((cal) => cal.id);
    return ({
      ...acc,
      pullCalendarIds: acc.pullCalendarIds.filter((calId) => accountCalIds.includes(calId)),
    });
  });
};

/**
 * Adjust non-pushEnabled accounts to have pushCalendarIds pointed to the next default calendar with writeAccess
 * @param {Account[]} accounts
 * @param {Object<string, ServiceCalendar[]>} calendarsList
 * @param {boolean} fixPushEnabled additionally fix pushEnabled account's pushCalendarId too
 * @return {Account[]}
 */
export const fixPushCalendarData = (accounts, calendarsList, fixPushEnabled=false) => {
  return accounts.map((account) => {
    const accountCals = calendarsList[account.email] || [];

    const pushCalendar = accountCals.find((cal) => cal.id === account?.pushCalendarId && cal.writeAccess);
    const isPushCalendarValid = !!pushCalendar;

    const writeCalendars = accountCals.filter((cal) => cal.writeAccess);
    const primaryCal = writeCalendars.find((cal) => cal.primary);
    const newDefaultPushCal = primaryCal || writeCalendars[0];

    const shouldFixPushCal = !isPushCalendarValid && (!account.pushEnabled || fixPushEnabled);
    return ({
      ...account,
      pushCalendarId: (shouldFixPushCal && newDefaultPushCal) ? newDefaultPushCal?.id : account.pushCalendarId,
    });
  });
};

export const selectBindAccount = (state) => {
  return state.integrationState.accounts.value.find((account) => account.pushEnabled === true);
};

export const selectThirdPartyAccountReachLimit = (state) => {
  return state.integrationState.accounts.value.filter((item) => item.service !== ZOOM).length >= MAX_3RD_PARTY_ACCOUNTS;
};

export const listAccounts = createAsyncThunk(
  'integration/listAccounts',
  /**
   * @param {Object} args
   * @param {*} thunkAPI
   * @param {boolean} args.query.calendarList
   * @return {Promise}
   */
  async (args, thunkAPI) => {
    const response = await getIntegrationSettings(args?.query);
    if (isEuCookie()) {
      thunkAPI.dispatch(integrationStore.actions.setIsEu(true));
    }
    return response;
  }
);

export const switchAccount = createAsyncThunk(
  'integration/switchAccount',
  async (newAccount, thunkAPI) => {
    const currentSettings = thunkAPI.getState().integrationState.accounts;
    const response = await patchIntegrationSettings({
      id: 'connections',
      value: currentSettings.value.map((account) => {
        return {
          ...account,
          pushEnabled: account.email === newAccount,
          webhooksEnabled: account.email === newAccount,
        };
      }),
    });
    return response;
  }
);

export const updateSyncPreferences = createAsyncThunk(
  'integration/updateCancelPreferences',
  async ({accountEmail, pushCalendarId, webhooksEnabled}, thunkAPI) => {
    const accounts = thunkAPI.getState().integrationState.accounts;

    const currentBindAccount = selectBindAccount(thunkAPI.getState());
    const isSwitchAccount = currentBindAccount?.email !== accountEmail;

    const response = await patchIntegrationSettings({
      id: 'connections',
      value: accounts.value.map((account) => {
        const currentPushCalId = account.email === accountEmail ? pushCalendarId : account.pushCalendarId;
        return {
          ...account,
          pushEnabled: account.email === accountEmail,
          pushCalendarId: currentPushCalId,
          webhooksEnabled: account.email === accountEmail ? webhooksEnabled : false,
          ...account.email === accountEmail && !account?.pullCalendarIds?.includes(account?.pushCalendarId) ? {
            pullCalendarIds: [
              currentPushCalId, // check conflict on the calendar which the event is written into by default
              ...account?.pullCalendarIds,
              // prevent exceeding server's limit which causes switch primary account failed
            ].slice(0, account?.service === MS? MS_MAX_PULL_CALS: undefined),
          } : null,
          ...isSwitchAccount? {
            // pullEnabled needs to be true to prevent host's busy time slots from being booked
            pullEnabled: account.email === accountEmail,
            // when switch to another primary account, disable non-primary account's conflict checking by default
            ...account.email !== accountEmail? {pullCalendarIds: []}: null,
          }: null,
        };
      }),
    });
    return response;
  }
);

export const updateAvailabilityPreferences = createAsyncThunk(
  'integration/updateAvailabilityPreferences',
  async ({accountEmail, values}, thunkAPI) => {
    const formValues = {...values};
    const selectedPullCalendarIds = values.checkCalendars
      .filter((cal) => cal.checked)
      .map((cal) => cal.id);

    // convert form values object to array of true values
    const busyTypes = formValues.busyEventTypes;
    const trueValuesArray = Object.entries(busyTypes)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key);
    const accounts = thunkAPI.getState().integrationState.accounts;

    const matchEmail = (element) => element.email === accountEmail;
    const index = accounts.value.findIndex(matchEmail);

    /** @type {Account} */
    const updatedAccount = {
      ...accounts.value[index],
      pullCalendarIds: selectedPullCalendarIds,
      pullEnabled: selectedPullCalendarIds.length > 0,
    };

    if (accounts.value[index].service === PROVIDER_TYPE.MS) {
      updatedAccount.unavailableStatuses = trueValuesArray;
    }

    const newAccounts = [...accounts.value];
    newAccounts[index] = updatedAccount;
    const response = await patchIntegrationSettings({
      id: 'connections',
      value: newAccounts,
    });
    return {
      response,
    };
  }
);

export const toggleAvailabilityPreferences = createAsyncThunk(
  'integration/toggleAvailabilityPreferences',
  async ({accountEmail}, thunkAPI) => {
    const accounts = thunkAPI.getState().integrationState.accounts;
    const accountCalendarsMap = thunkAPI.getState().integrationState.accountCalendars.mapping;

    const matchEmail = (element) => element.email === accountEmail;
    const index = accounts.value.findIndex(matchEmail);

    const account = accounts.value[index];
    /** @type {ServiceCalendar[]} */
    const accountCals = accountCalendarsMap[account.email];
    const checkingCalendars = accountCals.filter((cal) => account.pullCalendarIds.includes(cal.id));

    /** @type {Account} */
    const updatedAccount = {
      ...accounts.value[index],
    };

    if (checkingCalendars.length > 0) {
      // toggle off - remove all pullCalendarIds.
      updatedAccount.pullCalendarIds = [];
      updatedAccount.pullEnabled = false;
    } else {
      // toggle on - default to checking only first write access calendar
      const writeCalendars = accountCals.filter((cal) => cal.writeAccess);
      updatedAccount.pullCalendarIds = [writeCalendars[0].id];
      updatedAccount.pullEnabled = true;
    }

    const newAccounts = [...accounts.value];
    newAccounts[index] = updatedAccount;

    const response = await patchIntegrationSettings({
      id: 'connections',
      value: newAccounts,
    });
    return {
      response,
    };
  }
);

export const disconnectAccount = createAsyncThunk(
  'integration/disconnectAccount',
  async (mailToBeDeleted, thunkAPI) => {
    const currentSettings = thunkAPI.getState().integrationState.accounts;
    const itemToBeDeleted = currentSettings.value.find((account) => account.email === mailToBeDeleted);
    /** @type {Account[]} */
    const newValue = currentSettings.value.filter((account) => account.email !== mailToBeDeleted);
    // if the mail to be disconnected is selected now, need auto switch to another
    const doAutoSwitch = itemToBeDeleted.pushEnabled &&
      newValue.length > 0 &&
      newValue.some((account) => !account.blocked);
    let switchedEmail = '';

    let response = await patchIntegrationSettings({
      id: 'connections',
      value: [itemToBeDeleted],
      // connectionsDeleted will ignore other accounts' validity and make sure current item be deleted
      connectionsDeleted: true,
    });

    try {
      if (doAutoSwitch) {
        const autoSwitchAcct = newValue.find((acct) => !acct.blocked);
        response = await patchIntegrationSettings({
          id: 'connections',
          // connectionsOmitted will ignore other accounts' validity and only patch current item
          connectionsOmitted: true,
          value: [{...autoSwitchAcct, pushEnabled: true}],
        });
        switchedEmail = autoSwitchAcct.email;
      }
    } catch (error) {}

    const {calendarId} = getUserInfo() || {};
    if (calendarId) {
      // if broken connection disconnected, re-query the connection health
      thunkAPI.dispatch(validateUserConnections([calendarId.toLowerCase()]));
    }

    try {
      // re-fetch the connections list to refresh account errors
      response = await thunkAPI.dispatch(listAccounts()).unwrap();
    } catch (error) {};
    return {
      response,
      switchedEmail,
    };
  }
);

export const connectAccount = createAsyncThunk(
  'integration/connectAccount',
  async ({callbackValue}, thunkAPI) => {
    const {errorCode, mail, password, realEmail, result, service} = JSON.parse(callbackValue);
    let currentSettings = thunkAPI.getState().integrationState.accounts;
    // if email already exist then it's re-authorize
    const isReAuthorized = !!currentSettings.value.find((account) => account.email === mail);
    const isReAuthorizingPrimaryAcc = !!currentSettings.value.find((account) => account.email === mail)?.pushEnabled;
    const thirdPartyAccountReachLimit = selectThirdPartyAccountReachLimit(thunkAPI.getState());
    try {
      if (result === 'false' || ![GOOGLE, MS, ZOOM, APPLE].includes(service) || mail === '') {
        throw new Error();
      }
      // prevent user enter a different email when re-auth
      if (thirdPartyAccountReachLimit && !isReAuthorized) {
        throw new Error();
      }

      if (isReAuthorized) {
        // get the latest account state after reauthorizing
        await thunkAPI.dispatch(listAccounts());
        currentSettings = thunkAPI.getState().integrationState.accounts;
        // If the latest account state still has the auth error => reauthorization failed,
        // exit and throw.
        const reauthorizingAccount = currentSettings.value.find((account) => account.email === mail);
        if (reauthorizingAccount?.blocked && reauthorizingAccount?.error === GET_CONN_ERROR.INVALID_AUTH) {
          throw new Error();
        }
      }
      // reauth succeeded + calendar list should be populated for the reauth account:
      const currentCalendarsList = thunkAPI.getState().integrationState.accountCalendars.mapping;
      const doctoredAccounts = fixPushCalendarData(
        currentSettings.value,
        currentCalendarsList,
        isReAuthorizingPrimaryAcc,
      );
      const value = isReAuthorized ?
        doctoredAccounts.filter((acc) => acc.email === mail) :
        doctoredAccounts.concat({
          email: mail,
          realEmail,
          service,
          password,
          webhooksEnabled: true, // set sync cancellations as true by default
        });
      const response = await patchIntegrationSettings({
        id: 'connections',
        connectionsOmitted: isReAuthorized,
        value,
      });
      if (!isReAuthorized) {
        // new account was just added; fetch new calendar list with new account calendars info
        thunkAPI.dispatch(listAccounts());
      } else {
        const {calendarId} = getUserInfo() || {};
        if (calendarId) {
          // if broken connection re-authorized, should clear the error state in healthResults
          thunkAPI.dispatch(validateUserConnections([calendarId.toLowerCase()]));
        }
      }
      return {
        response,
        isReAuthorized,
      };
    } catch (error) {
      // multiple browser tabs concurrent modification conflict, only need to fetch latest result
      if (error.message === 'CAS check failed') {
        thunkAPI.dispatch(listAccounts());
        return thunkAPI.fulfillWithValue({
          ignore: true,
          isReAuthorized,
        });
      }
      let errmsg;
      if (errorCode === NWS_ERROR_CODE.LACK_OF_PERMISSION) {
        errmsg = 'integration.lackOfPermission';
      } else if (thirdPartyAccountReachLimit && !isReAuthorized) {
        errmsg = 'integration.thirdPartyAccountLimit';
      } else {
        errmsg = isReAuthorized? 'integration.reAuthorizeFailed': 'integration.connectFailed';
      }
      return thunkAPI.rejectWithValue(errmsg);
    }
  }
);
export const fetchUserHasLicense = createAsyncThunk(
  'integration/fetchUserHasLicense',
  async () => {
    const response = await requestLicenseFromPortal();
    return response;
  }
);

export const notifyHostConnectCalendar = createAsyncThunk(
  'integration/notifyHostConnectCalendar',
  async ({captchaFn, user}) => {
    const data = {};
    const {isDomainAdmin} = getUserInfo() || {};
    // if not admin, need a captcha
    if (!isDomainAdmin) {
      const {payload: passcode} = await captchaFn();
      data.captcha = passcode;
    }
    const response = await sendConnectCalendarEmail(user, data);
    return response;
  }
);

const generalPendingHandler = (state, action) => {
  state.loadingState.isLoading = true;
};
const generalFulfilledHandler = (state, action) => {
  state.loadingState.isLoading = false;
  if (!action.payload.ignore) {
    state.accounts = action.payload.response || action.payload;
  }
};
const generalRejectedHandler = (state, action) => {
  state.loadingState.isLoading = false;
};
export const integrationStore = createSlice({
  name: 'integrationStore',
  initialState,
  reducers: {
    actionHasRedirected: (state) => {
      state.hasActionRedirected = true;
    },
    updateFreeTrialInfo(state, action) {
      state.freeTrialInfo = action.payload;
    },
    setIsEu: (state, action) => {
      // once the flag is set, keep it on
      state.isEu = action.payload || state.isEu;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listAccounts.pending, (state, action) => {
      state.loadingState.listAccountState = 'pending';
      state.errorState.isError = false;
    });
    builder.addCase(listAccounts.fulfilled, (state, action) => {
      state.accountsInitiated = true;
      state.loadingState.listAccountState = 'fulfilled';
      state.accounts = action.payload.response || action.payload;
      if (!action.meta.arg?.query?.connectionType || !action.meta.arg?.query?.connectionType.match(/pushEnabled/i)) {
        state.accounts.value.forEach((account) => {
          const serviceCalendars = account.calendars || [];
          state.accountCalendars.mapping[account.email] = serviceCalendars;
          state.accountCalendars.hasLoaded = true;
        });
      }
      if (state.accountCalendars.hasLoaded) {
        state.accounts.value = cleanPullCalendarsData(state.accounts.value, state.accountCalendars.mapping);
      }
    });
    builder.addCase(listAccounts.rejected, (state, action) => {
      state.accountsInitiated = true;
      state.loadingState.listAccountState = 'rejected';
      state.errorState.isError = true;
      state.errorState.reason = 'storeErrors.listAccountsForCalendar';
    });
    builder.addCase(switchAccount.pending, generalPendingHandler);
    builder.addCase(switchAccount.fulfilled, generalFulfilledHandler);
    builder.addCase(switchAccount.rejected, (state, action) => {
      state.loadingState.isLoading = false;
      // grant is invalid or expired
      if (action.error.message?.includes(NWS_ERROR_CODE.OAUTH_TOKEN_NOT_EXIST) ||
          action.error.message?.includes(NWS_ERROR_CODE.INVALID_GRANT) ||
          action.error.message?.includes('Invalid Credentials')) {
        const newAccount = state.accounts.value.find((account) => account.email === action.meta.arg);
        newAccount && (newAccount.blocked = true);
      }
    });
    builder.addCase(disconnectAccount.pending, generalPendingHandler);
    builder.addCase(disconnectAccount.fulfilled, generalFulfilledHandler);
    builder.addCase(disconnectAccount.rejected, generalRejectedHandler);

    builder.addCase(updateAvailabilityPreferences.pending, generalPendingHandler);
    builder.addCase(updateAvailabilityPreferences.fulfilled, generalFulfilledHandler);
    builder.addCase(updateAvailabilityPreferences.rejected, generalRejectedHandler);

    builder.addCase(toggleAvailabilityPreferences.pending, generalPendingHandler);
    builder.addCase(toggleAvailabilityPreferences.fulfilled, generalFulfilledHandler);
    builder.addCase(toggleAvailabilityPreferences.rejected, generalRejectedHandler);

    builder.addCase(updateSyncPreferences.pending, generalPendingHandler);
    builder.addCase(updateSyncPreferences.fulfilled, generalFulfilledHandler);
    builder.addCase(updateSyncPreferences.rejected, generalRejectedHandler);

    builder.addCase(connectAccount.pending, generalPendingHandler);
    builder.addCase(connectAccount.fulfilled, generalFulfilledHandler);
    builder.addCase(connectAccount.rejected, generalRejectedHandler);
    builder.addCase(fetchUserHasLicense.fulfilled, (state, action) => {
      state.userHasLicense = action.payload.result;
      state.isPaidAccount = action.payload.isPaidAccount;
      state.userType = action.payload.userType;
      state.isPromoteBasic2NoLimit = action.payload.isPromoteBasic2NoLimit;
      state.accountHasLicense = action.payload.accountHasLicense;
      state.freeTrialInfo.remainDays = action.payload.remainDays || 0;
      state.freeTrialInfo.url = `${ZOOM_WEB_DOMAIN}${action.payload.freeTrialUrl}`;
      if (action.payload.trialing === true) {
        state.freeTrialInfo.status = FREE_TRIAL_CONDITION.TRIALLING;
      } else if (action.payload.trialing === false) {
        state.freeTrialInfo.status = FREE_TRIAL_CONDITION.QUAILIFY;
      } else {
        state.freeTrialInfo.status = FREE_TRIAL_CONDITION.NON_QUALIFY;
      }
      // if feedback disabled by web setting, we should hide feedback button
      if (action.payload.enableFeedback === false) {
        state.enableFeedback = false;
      }
      state.webPortalLanguage = action.payload.language;
    });

    builder.addCase(notifyHostConnectCalendar.pending, (state, action) => {
      state.notifyHostLoading = action.meta.arg.user;
    });
    builder.addCase(notifyHostConnectCalendar.fulfilled, (state, action) => {
      state.notifyHostLoading = '';
    });
    builder.addCase(notifyHostConnectCalendar.rejected, (state, action) => {
      state.notifyHostLoading = '';
    });
  },
});

export const {actionHasRedirected, calculateFreeTrialPeriod, updateFreeTrialInfo} = integrationStore.actions;

export default integrationStore.reducer;
