/**
 * for http status code: 401
 */
export class CaptchaError extends Error {
  /**
   * Create an UnauthorizedError
   * @param {String} message
   */
  constructor(message) {
    super(message);
    this.name = 'CaptchaError';
  }
}
