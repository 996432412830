import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * constuctor a date with timeZone
 */
export default class DateWithTimeZone extends Date {
  /**
   * @param {String} dateTime
   * @param {String} timeZone
   */
  constructor(dateTime, timeZone) {
    const isoDatePattern = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})(\.\d+)?([+-]\d{2}:?\d{2}|Z)$/;

    let dateTimeZone = null;
    let groups;

    if (typeof dateTime === 'object') {
      groups = JSON.stringify(dayjs(dateTime)).match(isoDatePattern);
    } else if (typeof dateTime === 'string') {
      groups = dateTime.match(isoDatePattern);
    }

    const offset = groups ? groups[4]: null;
    const hasOffset = !!offset;

    if (hasOffset) {
      dateTimeZone = dayjs(dateTime);
    } else if (timeZone) {
      dateTimeZone = dayjs.tz(dateTime, timeZone);
    } else {
      dateTimeZone = dayjs(dateTime);
    }
    super(dateTimeZone.valueOf());
  }
}
