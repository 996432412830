const hostRoutes = () => import('./hostRoutes');
const bookingRoutes = () => import('./bookingRoutes');

export const AdminFeaturesLayoutLoader = () => hostRoutes().then((m) => ({default: m.AdminFeaturesLayout}));
export const AppointmentsListLoader = () => hostRoutes().then((m) => ({default: m.AppointmentsList}));
export const BookingLinkHistoryLoader = () => hostRoutes().then((m) => ({default: m.BookingLinkHistory}));
export const BookingsListLoader = () => hostRoutes().then((m) => ({default: m.BookingsList}));
export const BrandingSettingsLoader = () => hostRoutes().then((m) => ({default: m.BrandingSettings}));
export const AvailabilitySettingsLoader = () => hostRoutes().then((m) => ({default: m.AvailabilitySettings}));
export const AccountSettingsLoader = () => hostRoutes().then((m) => ({default: m.AccountSettings}));
export const ProfileSettingsLoader = () => hostRoutes().then((m) => ({default: m.ProfileSettings}));
export const ConnectCallbackLoader = () => hostRoutes().then((m) => ({default: m.ConnectCallback}));
export const MobileLoader = () => hostRoutes().then((m) => ({default: m.Mobile}));
export const SettingsLayoutLoader = () => hostRoutes().then((m) => ({default: m.SettingsLayout}));
export const WelcomeLoader = () => hostRoutes().then((m) => ({default: m.Welcome}));
export const SetupLoader = () => hostRoutes().then((m) => ({default: m.Setup}));
export const PurchaseLoader = () => hostRoutes().then((m) => ({default: m.Purchase}));
export const OrganizerLandingPageLoader = () => hostRoutes().then((m) => ({default: m.OrganizerLandingPage}));
export const WorkflowsListLoader = () => hostRoutes().then((m) => ({default: m.WorkflowsList}));
export const AnalyticsLoader = () => hostRoutes().then((m) => ({default: m.Analytics}));
export const RoutingListLoader = () => hostRoutes().then((m) => ({default: m.RoutingList}));
export const ManagedEventsLoader = () => hostRoutes().then((m) => ({default: m.ManagedEvents}));
export const GroupsLoader = () => hostRoutes().then((m) => ({default: m.Groups}));
export const TeamsLoader = () => hostRoutes().then((m) => ({default: m.Teams}));
export const PollViewHarnessLoader = () => hostRoutes().then((m) => ({default: m.PollViewHarness}));
export const RouteFormBookingPageLoader = () => bookingRoutes().then((m) => ({default: m.RouteFormBookingPage}));
