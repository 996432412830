/*
 * Since recurring appointment time segments are made independent of
 * specific date, only a floating day of the week, we can use this
 * Time class to model a pure time of day using the js Date object
 * but with day portion locked to 1/1/1970, so that Time objects can be
 * compared / ordered properly
 *
 * Times will always use the UTC time to set hours and minutes
 */

/**
 * model a Time of day by mapping all dates to 1/1/1970
 * to ensure time ordering comparisons will behave
 */
export default class Time extends Date {
  /**
   * Create a Time with given hours and minutes
   * @param {Number} hours
   * @param {Number} min
   */
  constructor(hours=0, min=0) {
    const newDate = new Date(0);
    newDate.setUTCHours(hours);
    newDate.setUTCMinutes(min);
    return newDate;
  }

  /**
   * Create a new Date on 1/1/1970 preserving the hours and minutes
   * of the inputted date
   * @param {Date|string} date iso string or Date
   * @return {Time} normalized day
   */
  static fromDate(date) {
    const asDate = new Date(date);
    const hours = asDate.getUTCHours();
    const min = asDate.getUTCMinutes();
    return new Time(hours, min);
  }
}
