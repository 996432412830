import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {addDays, differenceInDays, startOfToday} from 'date-fns';

import {getReportsEvents, searchOrgMembers} from 'Api/ZCalendar';
import {getUserInfo} from 'Utils/integration';

export const fetchReportsEvents = createAsyncThunk(
  'analytics/fetchResportsEvents',
  async (data, thunkAPI) => {
    const selfCalendar = getUserInfo()?.calendarId || '';
    const selfUid = selfCalendar.split('@')[0] || '';
    const response = await getReportsEvents({
      ...data,
      userId: selfUid?.toLowerCase(),
    });
    return response;
  }
);

export const searchOrganizationMembers = createAsyncThunk(
  'analytics/searchOrgMembers',
  async ({query}, thunkAPI) => {
    const response = await searchOrgMembers(query);
    return response;
  }
);

const dateRangeDays = 60;

const slice = createSlice({
  name: 'AnalyticsStore',
  initialState: {
    loading: true,
    hasError: false,
    dateRangeDays,
    events: {},
    accountId: '',
    startDate: addDays(startOfToday(), -dateRangeDays + 1).toISOString(),
    endDate: startOfToday().toISOString(),
    selectUser: null,
    members: [],
    isSearchLoading: false,
  },
  reducers: {
    setDateRange(state, action) {
      const {endDate, startDate} = action.payload;
      state.startDate = startDate;
      state.endDate = endDate;
      state.dateRangeDays =
        differenceInDays(new Date(endDate), new Date(startDate)) + 1;
    },
    setSearchUser(state, action) {
      state.selectUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReportsEvents.pending, (state, action) => {
      state.loading = true;
      state.hasError = false;
    });
    builder.addCase(fetchReportsEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.events = action.payload;
    });
    builder.addCase(fetchReportsEvents.rejected, (state, action) => {
      state.loading = false;
      state.hasError = true;
    });
    builder.addCase(searchOrganizationMembers.pending, (state, action) => {
      state.isSearchLoading = true;
    });
    builder.addCase(searchOrganizationMembers.fulfilled, (state, action) => {
      state.isSearchLoading = false;
      state.members = action.payload.items;
    });
    builder.addCase(searchOrganizationMembers.rejected, (state, action) => {
      state.isSearchLoading = false;
    });
  },
});

export const {setDateRange, setSearchUser} = slice.actions;
export default slice.reducer;
