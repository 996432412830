import {useCallback, useContext, useRef} from 'react';

import CaptchaContext from './CaptchaContext';
import {captchaResultType} from './CaptchaDownload';

import {fetchJwtToken} from 'Api/Calendar';
import config from 'Config/config';
import {CaptchaError} from 'Error/CaptchaError';

/**
 * @param {String} calendarId
 * @return {Function}
 */
export function useCaptcha(calendarId) {
  const captcha = useContext(CaptchaContext);
  const counts = useRef(0);

  const run = useCallback(async (resolve, reject, increment=true) => {
    if (counts.current > config.maxCallCaptchaCount) {
      // exceed max counts, to make sure, when return back, user can click again
      counts.current = 0;
      reject(new CaptchaError('Exceed max counts for captcha'));
      return;
    }
    if (increment) {
      counts.current += 1;
    }

    let type = '';
    let passcode = '';
    try {
      ({payload: passcode, type} = await captcha.current.runCaptcha());
    } catch (error) {
      console.error('captcha run error', error);
      reject(error);
      return;
    }
    if (type === captchaResultType.error) {
      reject(new CaptchaError('error ocurrs, please try again later'));
      return;
    } else if (type === captchaResultType.expired) {
      run(resolve, reject);
      return;
    } else if (type === captchaResultType.dismissed) {
      // user clicked outside captcha to close; re-run but do
      // not increment count.
      run(resolve, reject, false);
      return;
    }

    try {
      await fetchJwtToken(calendarId, passcode);
      // if fetch jwt token successfully, jwt token will be writted into cookie
      counts.current = 0;
      resolve(passcode);
    } catch (error) {
      console.error('server jwt exchange failed', error);
      reject(error);
    }
  }, [calendarId, captcha]);
  return run;
}

/**
 * @return {Function}
 */
export function useCaptchaPasscode() {
  const captcha = useContext(CaptchaContext);

  const run = useCallback(async (resolve, reject) => {
    let type = '';
    let passcode = '';
    try {
      ({payload: passcode, type} = await captcha.current.runCaptcha());
    } catch (error) {
      console.error('captcha run error', error);
      reject(error);
      return;
    }
    if (type === captchaResultType.error) {
      reject(new CaptchaError(captchaResultType.error));
      return;
    } else if (type === captchaResultType.expired) {
      run(resolve, reject);
      return;
    } else if (type === captchaResultType.dismissed) {
      // user clicked outside captcha to close; stop the execution
      reject(new CaptchaError(captchaResultType.dismissed));
      return;
    }
    resolve(passcode);
  }, [captcha]);
  return run;
}
