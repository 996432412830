import * as React from 'react';


import styled from '@emotion/styled';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';

import {snackbarActions} from 'Store/SnackbarStore';
import zoomPalette from 'zoomPalette';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const zmSecondaryToastStyle = ({theme}) => `
background: ${theme.palette.common.swatch[90]};
color: white;
padding: 10px 16px;
border-radius: 10px;
box-shadow: 0px 24px 48px rgba(19, 22, 25, 0.1), 0px 12px 24px rgba(19, 22, 25, 0.1);
`;

const ZmToast = styled(Alert)((props) => `
  ${props.variant === 'secondary' && zmSecondaryToastStyle(props)}
`);
const formatColorStyle = (type) => {
  if (type === 'success') {
    return {
      color: zoomPalette['green']['90'],
      background: zoomPalette['green']['10'],
    };
  }
  if (type === 'error' || type === 'warning') {
    return {
      color: zoomPalette['gold']['90'],
      background: zoomPalette['gold']['10'],
    };
  }
  return {};
};
/**
 * CustomizedSnackbars Component
 * @param {Object} props
 * @return {ReactElement}
 */
export default function CustomizedSnackbars(props) {
  const {id, message, noIcon, severity, theme, verticalPosition} = props;
  const [open, setOpen] = React.useState(true);

  const {t} = useTranslation();

  const dispatch = useDispatch();
  // support i18n placeholder replace
  const isMsgObj = message !== null && typeof message === 'object';
  const finalMessage = message ?
    t(isMsgObj ? message.msg : message, isMsgObj ? message.params : undefined): '';

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onExited = (e) => {
    if (!open) {
      dispatch(snackbarActions.removeSnackbar(id));
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: verticalPosition,
        horizontal: 'center',
      }}
      key={id}
      open={open}
      autoHideDuration={finalMessage.length > 50 ? 5000: 3000}
      onClose={handleClose}
      TransitionProps={{onExited: onExited}}
    >
      {theme === 'zm' ?
        <ZmToast
          onClose={handleClose}
          variant="secondary"
          severity={severity ? severity : 'warning'} sx={{width: '100%'}}
          icon={noIcon ? false : undefined}
        >
          {finalMessage}
        </ZmToast>:
        <MuiAlert
          severity={severity? severity: 'warning'}
          sx={{
            alignItems: 'center',
            fontWeight: 450,
            width: '100%',
            ...formatColorStyle(severity),
          }}
        >
          {finalMessage}
        </MuiAlert>
      }
    </Snackbar>
  );
}
