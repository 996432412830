import {compareAsc} from 'date-fns';
import {DateTime} from 'luxon';

import {getTimeFormatFromSetting} from 'Utils';
import DateWithTimeZone from 'Utils/DateWithTimeZone';

/**
 * Constructs a luxon DateTime, accepting either an ISO string or a JS Date object.
 * @param {string|Date} date ISO or JS Date
 * @param {?string} timeZone
 * @param {?boolean} keepLocalTime
 * @return {DateTime}
 */
export const toDateTime = (date, timeZone, keepLocalTime=false) => {
  if (!date) {
    return DateTime.now().setZone(timeZone, {
      keepLocalTime: keepLocalTime,
    });
  }
  if (date instanceof Date) {
    return DateTime.fromJSDate(date).setZone(timeZone, {
      keepLocalTime: keepLocalTime,
    });
  } else if (typeof date === 'string') {
    return DateTime.fromISO(date).setZone(timeZone, {
      keepLocalTime: keepLocalTime,
    });
  } else if (date instanceof DateTime) {
    return date.setZone(timeZone, {
      keepLocalTime: keepLocalTime,
    });
  } else {
    console.error('Given date was not a string or Date object', date);
  }
};

/**
 *
 * @param {Date|string} date
 * @param {?string} timeZone
 * @return {string}
 */
export const startOfDayInZone = (date, timeZone) => {
  const day = toDateTime(date, timeZone).startOf('day');
  return day.toUTC().toISO();
};

/**
 *
 * @param {Date|string} date
 * @param {?string} timeZone
 * @return {string}
 */
export const endOfDayInZone = (date, timeZone) => {
  const day = toDateTime(date, timeZone).endOf('day');
  return day.toUTC().toISO();
};

/**
 *
 * @param {Date|string} date
 * @param {?string} timeZone
 * @return {string}
 */
export const startOfNextDayInZone = (date, timeZone) => {
  const day = toDateTime(date, timeZone)
    .startOf('day')
    .plus({days: 1});
  return day.toUTC().toISO();
};

/**
 * @param {Date|string} date
 * @param {?string} timeZone
 * @return {string} ISO of start of the given date in the given time zone
 */
export const toDateInZone = (date, timeZone) => {
  // If user selects calendar date 11/22 - we want to preserve the DATE meaning,
  // so only change the time zone while keeping the local time (midnight) unchanged

  return toDateTime(date, timeZone, true).startOf('day').toUTC().toISO();
};

/**
 * @param {Array<Object>} segments
 * @param {String} timeZone
 * @return {Object}
 */
export const groupSegsByDay = (segments, timeZone) => {
  return segments?.reduce((prev, curr, idx) => {
    const dayVal = startOfDayInZone(curr.start, timeZone);
    console.log(
      'grouped', curr.start,
      'into day group', dayVal, timeZone);
    prev[dayVal] = prev[dayVal] ? [...prev[dayVal], curr] : [curr];
    return prev;
  }, {});
};

/**
 * @param {Object} dateGroupedSegments mapping from iso date strings to array of segments
 * @return {Array<String>} array of iso date string keys for dateGroupedSegments
 */
export const sortedDates = (dateGroupedSegments) => {
  return Object.keys(dateGroupedSegments || []).sort((a, b) =>
    compareAsc(
      new DateWithTimeZone(a),
      new DateWithTimeZone(b),
    )
  );
};
/**
 * @param {Date|string} date
 * @param {?string} timeZone
 * @param {?string} timeFormat
 * @return {string}
 */
export const renderZonedTime = (date, timeZone, timeFormat = '12h') => {
  return toDateTime(date, timeZone).toFormat(
    getTimeFormatFromSetting(timeFormat, true)
  );
};

export const createDefaultDaySegment = (dayValue, timeZone) => {
  const zonedStartOfDay = toDateTime(dayValue, timeZone).startOf('day');
  const newSegmentStart = zonedStartOfDay.set({hour: 9}).toUTC().toISO();
  const newSegmentEnd = zonedStartOfDay.set({hour: 17}).toUTC().toISO();
  return {
    start: newSegmentStart,
    end: newSegmentEnd,
  };
};

/**
 * Given sorted array of segments for a given day,
 * attempts to create a 1 hr long segment starting 1 hour after previous segment.
 * If not enough remaining time exists in this day, fills out the rest of the day with a single segment.
 * @param {Array<Object>} existingSegs
 * @param {String} timeZone IANA timezone
 * @return {Object}
 */
export const createNextSegment = (existingSegs, timeZone) => {
  const lastEnd = existingSegs[existingSegs.length-1].end;
  const prevSegEnd = toDateTime(lastEnd, timeZone);
  const defaultSegDuration = 1; // in hours
  const defaultSegBuffer = 1; // in hours

  const startOfNextDay = prevSegEnd.startOf('day').plus({days: 1});

  // ensure that newSegEnd will always be constrained to the current day
  const newSegEnd = DateTime.min(
    prevSegEnd.plus({hours: defaultSegDuration + defaultSegBuffer}),
    startOfNextDay,
  );

  // work backwards from newSegEnd to ensure still within same day
  let newSegStart = newSegEnd.minus({hours: defaultSegDuration});
  // split the remaining time period in half when not enough time exists
  if (newSegStart < prevSegEnd) {
    const remainingMinutes = prevSegEnd.endOf('day').diff(prevSegEnd, 'minutes').values.minutes;
    newSegStart = prevSegEnd.plus({minutes: remainingMinutes / 2});
  }

  return {
    start: newSegStart.toUTC().toISO(),
    end: newSegEnd.toUTC().toISO(),
  };
};

/**
 * Returns whether this segment specifies a date override 'Unavailable' rule
 * @param {Object} seg
 * @param {string} timeZone
 * @return {boolean}
 */
export const isSegmentOverrideUnavailable = (seg, timeZone) => {
  return (
    seg?.start && seg?.end &&
    seg.start === seg.end &&
    toDateTime(seg.start, timeZone).valueOf() === toDateTime(seg.start, timeZone).startOf('day').valueOf()
  );
};

/**
 * Generate yyyy-mm-dd date string
 * @param {string} dataString
 * @return {string}
 */
export const getFormatTime = (dataString) => {
  const date = new Date(dataString);
  const year = date.toLocaleString('en-US', {year: 'numeric'});
  const month = date.toLocaleString('en-US', {month: '2-digit'});
  const day = date.toLocaleString('en-US', {day: '2-digit'});
  return year + '-' + month + '-' + day;
};
