/* eslint-disable */
/**
 * Array.flat() polyfill
 * Adapted from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat#reduce_concat_isArray_recursivity
 */
if (!Array.prototype.flat) {
  Array.prototype.flat = function (depth) {
    'use strict';
    // If no depth is specified, default to 1
    if (depth === undefined) {
      depth = 1;
    }
    // Recursively reduce sub-arrays to the specified depth
    var flatten = function (arr, depth) {
      // If depth is 0, return the array as-is
      if (depth < 1) {
        return arr.slice();
      }
      // Otherwise, concatenate into the parent array
      return arr.reduce(function (acc, val) {
        return acc.concat(Array.isArray(val) ? flatten(val, depth - 1) : val);
      }, []);
    };
    return flatten(this, depth);
  };
}

if (!String.prototype.matchAll) {
  String.prototype.matchAll = function(regexp) {
    let matches = [];
    this.replace(regexp, function() {
      let arr = [...arguments];
      let extras = arr.splice(-2);
      arr.index = extras[0];
      arr.input = extras[1];
      matches.push(arr);
    });
    return matches;
  };
}

if (!Object.fromEntries) {
  Object.fromEntries = function(entries) {
    if (!entries || !entries[Symbol.iterator]) {
      throw new Error('Object.fromEntries() requires an iterable argument');
    }
    const obj = {};
    for (let [key, value] of entries) {
      obj[key] = value;
    }
    return obj;
  };
}

if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function(str, newStr){
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
            return this.replace(str, newStr);
    }
    // If a string
    return this.replace(new RegExp(str, 'g'), newStr);
  };
}
