import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import {
  listBusyEvents,
} from 'Api/ZCalendar';

const initialState = {
  pageToken: null,
  busyEvents: {},
  isLoading: false,
  errorState: {
    isError: false,
    reason: '',
  },
  nextPageToken: null,
};

// change busyEvents to object keyed by uId
// busyEvents: {
//   userId1: [busyEvent],
//   userId2: [busyEvents2],
// }

export const listBusyEventsForCalendar = createAsyncThunk(
  'hostBusyEvents/listByCalendar',
  async ({timeMax, timeMin, users}, thunkAPI) => {
    const queryParams = new URLSearchParams({
      timeMin,
      timeMax,
    });
    let queryWithUsers = queryParams.toString();
    if (users) {
      const queryString = users.map((user) => `user=${user.toLowerCase()}`).join('&');
      queryWithUsers += `&${queryString}`;
    }
    const response = await listBusyEvents(queryWithUsers);
    return response;
  }
);

export const hostBusyEventsStore = createSlice({
  name: 'hostBusyEventsStore',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listBusyEventsForCalendar.fulfilled, (state, action) => {
      state.isLoading = false;
      const events = {};
      action.payload.items.forEach((item) => {
        const email = item.host;
        const uId = email.substring(0, email.indexOf('@'));
        // TODO don't add if uppercase
        // if (!events.has(item.host)) {
        events[uId] = item.value;
        // }
      });
      state.busyEvents = events;

      // if (action.meta.arg?.pageToken) {
      //   state.busyEvents = merge(state.busyEvents, eventsFlattened, 'eventId');
      // } else {
      //   state.busyEvents = eventsFlattened;
      // }
      state.pageToken = action.payload.nextPageToken;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listBusyEventsForCalendar.pending, (state, action) => {
      state.isLoading = true;
      state.errorState = {
        isError: false,
        reason: '',
      };
    });
    builder.addCase(listBusyEventsForCalendar.rejected, (state, action) => {
      state.isLoading = false;
      state.errorState = {
        isError: true,
        reason: 'storeErrors.listBusyEventsForCalendar',
      };
    });
  },
});

export default hostBusyEventsStore.reducer;
