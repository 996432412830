import omit from 'lodash/omit';
import {createSearchParams} from 'react-router-dom';

import {defaultHeaders, fetchCall, fetchCallWithErrorBody} from './ApiUtils';

import {getVirtualUserEmail, readJWTPayload, skipCaptchaTests, strEq} from 'Utils';
import {PROVIDER_TYPE, SCOPE_TYPE} from 'Utils/consts';
import {
  forceRefreshClientToken,
  getToken,
  getUserInfo,
  isTokenExpired,
  shouldBuyLicense,
  styleInZoomClient,
} from 'Utils/integration.js';
const useProxy =
  process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

let zschedulerURL = 'zscheduler/v1';
if (useProxy) {
  // use proxy on dev env for cors issue
  zschedulerURL = 'api/zscheduler/v1';
}

const skipCaptchas = skipCaptchaTests();

export const getZCalToken = async () => {
  // if Zoom Client token has expired, fetch the new token before sending request
  if (styleInZoomClient && isTokenExpired()) {
    await forceRefreshClientToken();
  }
  const token = getToken();
  if (!token) {
    // should not show error message for unlicensed user, because unlicensed user doesn't have a token
    !shouldBuyLicense && console.error('no zcalendar token was found');
    throw new Error('401');
  }
  return token;
};

/**
 * Calls underlying helper function fetchCall with attached ZCal bearer token if present
 * If no ZCal token found, throws '401'.
 * @param {string} url
 * @param {string} method
 * @param {?Object} data
 * @param {?Object} extraHeaders
 * @return {Promise}
 */
export const fetchZCal = async (url, method, data, extraHeaders) => {
  const token = await getZCalToken();
  const headers = {
    ...defaultHeaders,
    ...extraHeaders,
    'Authorization': `Bearer ${token}`,
  };
  return fetchCall(url, method, data, headers);
};

/**
 * Calls underlying helper function fetchCallWithErrorBody with attached ZCal bearer token if present
 * If no ZCal token found, throws '401'.
 * @param {string} url
 * @param {string} method
 * @param {?Object} data
 * @return {Promise}
 */
export const fetchZCalWithErrorBody = async (url, method, data) => {
  const token = await getZCalToken();
  const headers = {
    ...defaultHeaders,
    'Authorization': `Bearer ${token}`,
  };
  return fetchCallWithErrorBody(url, method, data, headers);
};

/**
 *
 * @param {Scope} scope
 * @param {Object} query
 * @return {Object}
 */
export const attachScope = (scope, query) => {
  const q = {...query};
  if (scope) {
    if (scope?.type === SCOPE_TYPE.USER) {
      const selfUser = getUserInfo();
      if (!strEq(scope.id, selfUser?.userId) && !selfUser?.isCciAccount) {
        return {...q, user: getVirtualUserEmail(scope.id)};
      }
    } else if (scope?.type === SCOPE_TYPE.TEAM) {
      return {...q, team: scope.id};
    } else if (scope?.type === SCOPE_TYPE.GROUP) {
      return {...q, gid: scope.id};
    } else if (scope?.type === SCOPE_TYPE.ACCOUNT) {
      return {...q, aid: getUserInfo()?.accountId};
    }
  }
  return q;
};

/**
 * createAppointment request
 * @param {String} scope
 * @param {Object} data
 * @param {string} teamId
 * @return {Promise}
 */
export const createAppointment = async (scope, data) => {
  const searchParams = new URLSearchParams(attachScope(scope));
  const url = `${zschedulerURL}/appointments?${searchParams.toString()}`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * createOneOffAppointment request
 * @param {string} calendarId
 * @param {Object} data
 * @return {Promise}
 */
export const createOneOffAppointment = async (calendarId, data) => {
  const url = `${zschedulerURL}/appointments/adhoc`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * edit availability poll request
 * @param {string} calendarId
 * @param {string} pollId
 * @param {Object} data
 * @param {boolean} notifyVoters
 * @return {Promise}
 */
export const editAvailabilityPoll = async (calendarId, pollId, data, notifyVoters) => {
  const q = {};
  if (notifyVoters) {
    q.sendUpdates = 'all';
  }
  if (skipCaptchas || process.env.NODE_ENV === 'development') {
    q.testing = 'true';
  }
  const url = `${zschedulerURL}/pendingEvents/poll/${pollId}?${new URLSearchParams(q).toString()}`;
  return await fetchZCal(url, 'PATCH', data);
};

/**
 * Clear reserved times for the adhoc appt (one-time / availability poll)
 * @param {string} adhocApptId
 * @return {Promise}
 */
export const clearAdhocReservedEvents = async (adhocApptId) => {
  const url = `${zschedulerURL}/pendingEvents/${adhocApptId}/clearReservedTime`;
  return await fetchZCalWithErrorBody(url, 'PUT');
};

/**
 * get user request
 * @param {string} userId
 * @return {Promise}
 */
export const getUser = async (userId) => {
  const url = `${zschedulerURL}/organization/member/${userId}`;
  const data = await fetchZCal(url, 'GET');
  // User's data is returned in a list; get the first item.
  return data.items[0];
};

/**
 * search organization members request
 * @param {?Object} query
 * @param {?String} query.email
 * @return {Promise}
 */
export const searchOrgMembers = async (query) => {
  const queryParams = new URLSearchParams(query);
  const url = queryParams.get('q') ?
    `${zschedulerURL}/organization/members/search?${queryParams.toString()}` :
    `${zschedulerURL}/organization/members/search`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * list organization members request
 * @param {?Object} query
 * @param {?String} query.email
 * @return {Promise}
 */
export const listOrgMembers = async (query) => {
  const queryParams = new URLSearchParams(query);
  const url = `${zschedulerURL}/organization/members?${queryParams.toString()}`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * list appointments request
 * @param {Scope} scope
 * @param {?Object} query See https://developers.google.com/calendar/api/v3/reference/events/list#parameters
 * @param {?String} query.orderBy
 * @param {?String} query.timeMin ISO String
 * @return {Promise}
 */
export const listAppointments = async (scope, query) => {
  const queryParams = new URLSearchParams(attachScope(scope, query));
  const url = `${zschedulerURL}/appointments?${queryParams.toString()}`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * get appointment request
 * @param {Scope} scope
 * @param {string} apptId
 * @param {?Object} query
 * @return {Promise}
 */
export const getApptDetail = async (scope, apptId, query) => {
  const queryParams = new URLSearchParams(attachScope(scope, query));
  const url = `${zschedulerURL}/appointments/${apptId}?${queryParams.toString()}`;
  return await fetchZCal(url, 'GET');
};

/**
 * Fetch Appointment Data for internal booking
 * @param {string} calId
 * @param {string} apptId
 * @param {?Object} query
 * @param {?bool} query.showFreeBusy
 * @param {?Object} headers
 * @return {Promise}
 */
export const fetchApptData = async (calId, apptId, query, headers) => {
  const queryParams = new URLSearchParams({...query, user: calId});
  const url = `${zschedulerURL}/appointments/${apptId}?${queryParams.toString()}`;
  return await fetchZCal(url, 'GET', undefined, headers);
};

/**
 * Fetch Appointment/Event
 * @param {string} calId
 * @param {string} apptId
 * @param {?Object} query
 * @param {?bool} query.showFreeBusy
 * @param {?Object} headers
 * @return {Promise}
 */
export const fetchAppointment = async (calId, apptId, query, headers) => {
  const queryParams = new URLSearchParams({...query, user: calId});
  const url = `${zschedulerURL}/appointments/${apptId}/availableTimes?${queryParams.toString()}`;
  return await fetchZCal(url, 'GET', undefined, headers);
};

/**
 * Fetches multiple appointments, will throw the error of the first request to fail
 * @param {string} calId
 * @param {Array<string>} apptIds
 * @param {?Object} query
 * @return {Promise}
 */
export const fetchAppointments = async (calId, apptIds, query) => {
  return Promise.all(apptIds.map((apptId) => fetchAppointment(calId, apptId, query)));
};

/**
 * list preset templates request
 * @return {Promise}
 */
export const listPresetWfTemplates = async () => {
  const url = `${zschedulerURL}/workflows/templates`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * list message templates request
 * @return {Promise}
 */
export const listMessageTemplates = async () => {
  const url = `${zschedulerURL}/workflows/templates/messages`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * delete appointment request
 * @param {Scope} scope
 * @param {String} apptId
 * @return {Promise}
 */
export const deleteAppointment = async (scope, apptId) => {
  const queryParams = new URLSearchParams(attachScope(scope));
  const url = `${zschedulerURL}/appointments/${apptId}?${queryParams.toString()}`;
  return await fetchZCalWithErrorBody(url, 'DELETE', undefined);
};

/**
 * patchAppointment request
 * @param {String} apptId
 * @param {Scope} scope
 * @param {Object} data
 * @return {Promise}
 */
export const patchAppointment = async (apptId, scope, data) => {
  const queryParams = new URLSearchParams(attachScope(scope));
  const url = `${zschedulerURL}/appointments/${apptId}?${queryParams.toString()}`;
  return await fetchZCal(url, 'PATCH', data);
};

/**
 * list bookings request
 * @param {Scope} scope
 * @param {?Object} query See https://developers.google.com/calendar/api/v3/reference/events/list#parameters
 * @param {?String} query.orderBy
 * @param {?String} query.timeMin ISO String
 * @param {?String} query.timeMax ISO String
 * @return {Promise}
 */
export const listBookings = async (scope, query) => {
  const queryParams = new URLSearchParams(attachScope(scope, query));
  const url = `${zschedulerURL}/events?${queryParams.toString()}`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * edit bookings request
 * @param {Scope} scope
 * @param {String} id
 * @param {?String} query.orderBy
 * @param {?String} query.timeMin ISO String
 * @param {?String} query.timeMax ISO String
 * @param {?Object} data
 * @return {Promise}
 */
export const editBooking = async (scope, id, data) => {
  const queryParams = new URLSearchParams(attachScope(scope));
  const url = `${zschedulerURL}/events/${id}?${queryParams.toString()}`;
  return await fetchZCal(url, 'PATCH', data);
};

/**
 * reschedule booked event
 * @param {String} attendeeId
 * @param {Object} data
 * @param {?Object} headers
 * @return {Promise}
 */
export const rescheduleEventNew = async (attendeeId, data, headers) => {
  const url = `${zschedulerURL}/attendees/${attendeeId}`;
  return await fetchZCal(url, 'PATCH', data, headers);
};

/**
 * query free busy events request
 * @param {?Object} query See https://developers.google.com/calendar/api/v3/reference/events/list#parameters
 * @param {?String} query.timeMin ISO String
 * @param {?String} query.timeMax ISO String
 * @return {Promise}
 */
export const listBusyEvents = async (query) => {
  const url = `${zschedulerURL}/events/busy?${query}`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * get booked event by attendeeId
 * @param {String} attendeeId
 * @param {String} eventId
 * @return {Promise}
 */
export const getAttendee = async (attendeeId) => {
  const url = `${zschedulerURL}/attendees/${attendeeId}`;
  return await fetchZCal(url, 'GET');
};

/**
 * delete booked event
 * @param {Scope} scope
 * @param {String} eventId
 * @return {Promise}
 */
export const deleteEvent = async (scope, eventId) => {
  const queryParams = new URLSearchParams(attachScope(scope, {sendUpdates: 'all'}));
  const url = `${zschedulerURL}/events/${eventId}?${queryParams.toString()}`;
  return await fetchZCalWithErrorBody(url, 'DELETE', undefined);
};

/**
 * Remove / unbook a booker from a booked appointment
 * @param {Scope} scope
 * @param {String} attendeeId
 * @param {Object} data
 * @return {Promise}
 */
export const removeBookerFromEventNew = async (scope, attendeeId, data) => {
  const queryParams = new URLSearchParams(attachScope(scope));
  const url = `${zschedulerURL}/attendees/${attendeeId}?${queryParams.toString()}`;
  return await fetchZCalWithErrorBody(url, 'DELETE', data);
};


/**
 * create workflow request
 * @param {String} calId
 * @param {Object} data
 * @return {Promise}
 */
export const createWorkflow = async (calId, data) => {
  const url = `${zschedulerURL}/workflows`;
  if (process.env.REACT_APP_ENABLE_SMS !== 'true' && data['text']) {
    delete data['text'];
  }
  return await fetchZCal(url, 'POST', data);
};

/**
 * list workflows request
 * @param {String} calId
 * @return {Promise}
 */
export const listWorkflows = async (calId) => {
  const url = `${zschedulerURL}/workflows?`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * patch workflow request
 * @param {String} workflowId
 * @param {String} calId
 * @param {Object} data
 * @return {Promise}
 */
export const patchWorkflow = async (workflowId, calId, data) => {
  const url = `${zschedulerURL}/workflows/${workflowId}`;
  if (process.env.REACT_APP_ENABLE_SMS !== 'true' && data['text']) {
    delete data['text'];
  }
  return await fetchZCal(url, 'PATCH', data);
};

/**
 * delete workflow request
 * @param {String} calId
 * @param {String} workflowId
 * @return {Promise}
 */
export const deleteWorkflow = async (calId, workflowId) => {
  const url = `${zschedulerURL}/workflows/${workflowId}`;
  return await fetchZCalWithErrorBody(url, 'DELETE', undefined);
};

/**
 * get domain logo request
 * @return {Promise}
 */
export const getPersonalLogo = async () => {
  const url = `${zschedulerURL}/settings/logo`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * get domain bookingPageColor request
 * @return {Promise}
 */
export const getPersonalBookingPageColor = async () => {
  const url = `${zschedulerURL}/settings/bookingPageColor`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * get schedules request
 * @param {?Object} query
 * @return {Promise}
 */
export const getSchedules = async (query) => {
  const queryParams = createSearchParams(query);
  const url = `${zschedulerURL}/availability?${queryParams.toString()}`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * create new schedule request
 * @param {Object} data
 * @return {Promise}
 */
export const createSchedule = async (data) => {
  const url = `${zschedulerURL}/availability`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * patch schedule request
 * @param {String} availabilityId
 * @param {Object} data
 * @return {Promise}
 */
export const patchSchedule = async (availabilityId, data) => {
  const url = `${zschedulerURL}/availability/${availabilityId}`;
  return await fetchZCal(url, 'PATCH', data);
};

/**
 * delete schedule request
 * @param {String} availabilityId
 * @param {Object} data
 * @return {Promise}
 */
export const deleteSchedule = async (availabilityId, data) => {
  const url = `${zschedulerURL}/availability/${availabilityId}`;
  return await fetchZCalWithErrorBody(url, 'DELETE', data);
};

/**
 * post default schedule request
 * @param {String} availabilityId
 * @param {Object} data
 * @return {Promise}
 */
export const postDefaultSchedule = async (availabilityId, data) => {
  const url = `${zschedulerURL}/availability/${availabilityId}/defaultAvailability`;
  return await fetchZCalWithErrorBody(url, 'POST', data);
};

/**
 * get appointment for availability request
 * @param {?Object} query
 * @return {Promise}
 */
export const getAppointmentsForAvailability = async (query) => {
  const queryParams = new URLSearchParams(query);
  const url = `${zschedulerURL}/availability/appointments?${queryParams.toString()}`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * patch schedule request
 * @param {String} availabilityId
 * @param {Object} data
 * @return {Promise}
 */
export const patchActiveAppts = async (availabilityId, data) => {
  const url = `${zschedulerURL}/availability/${availabilityId}/appointments`;
  return await fetchZCalWithErrorBody(url, 'PATCH', data);
};

/**
 * get integration settings request
 * @param {?Object} query
 * @param {string} query.calendarList
 * @param {string} query.primary
 * @return {Promise}
 */
export const getIntegrationSettings = async (query) => {
  const queryParams = new URLSearchParams(query);
  const url = `${zschedulerURL}/settings/connections?${queryParams.toString()}`;
  return await fetchZCal(url, 'GET', undefined);
};

/**
 * patch integration settings request
 * @param {Object} data
 * @return {Promise}
 */
export const patchIntegrationSettings = async (data) => {
  const url = `${zschedulerURL}/settings/connections`;
  return await fetchZCal(url, 'PATCH', {
    ...data,
    // if 3rd party account invalid, server will add additional `blocked` and `error` field but we should not send back
    // if calendarList=true, server will add additional calendars field, but we should not send back
    value: data.value.map((account) => {
      // apple calendar doesn't support webhook, js must set it to false
      if (account.service === PROVIDER_TYPE.APPLE) {
        account = {...account, webhooksEnabled: false};
      }
      return omit(account, ['blocked', 'error', 'calendars']);
    }),
  });
};

/**
 * patch user logo settings request
 * @param {Object} data
 * @param {string} data.id
 * @param {string} data.data base64 logo data
 * @return {Promise}
 */
export const patchPersonalLogo = async (data) => {
  const url = `${zschedulerURL}/settings/logo`;
  return await fetchZCal(url, 'PATCH', data);
};

/**
 * patch user bookingPageColor settings request
 * @param {Object} data
 * @param {string} data.id
 * @param {string} data.data
 * @return {Promise}
 */
export const patchPersonalBookingPageColor = async (data) => {
  const url = `${zschedulerURL}/settings/bookingPageColor`;
  return await fetchZCal(url, 'PATCH', data);
};

/**
 * get host phone numbers request
 * @return {Promise}
 */
export const listHostPhones = async () => {
  const url = `${zschedulerURL}/settings/phoneNumbers`;
  return await fetchZCal(url, 'GET');
};

/**
 * patch host phone numbers request
 * @param {Object} data
 * @return {Promise}
 */
export const patchHostPhones = async (data) => {
  const url = `${zschedulerURL}/settings/phoneNumbers`;
  return await fetchZCal(url, 'PATCH', data);
};

/**
 * @typedef VerificationBody
 * @property {?string} firstName
 * @property {?string} lastName
 * @property {string} phoneNumber
 */

/**
 * Internal send verification code request
 * @param {VerificationBody} data
 * @return {Promise}
 */
export const sendVerificationCode = async (data) => {
  const url = `${zschedulerURL}/validators/code`;
  return await fetchZCalWithErrorBody(url, 'POST', data);
};

/**
 * Validates slug
 * @param {slugData} data
 * @return {Promise}
 */
export const validateSlug = async (data) => {
  const url = `${zschedulerURL}/validators/slug`;
  return await fetchZCalWithErrorBody(url, 'POST', data);
};

/**
 * Internal send verification code request
 * @param {?Object} query
 * @param {Object} query.user scheduler email
 * @return {Promise}
 */
export const fetchUserSlugSetting = async (query) => {
  let searchParams = '';
  if (query) {
    searchParams = new URLSearchParams(query).toString();
  }
  const url = `${zschedulerURL}/settings/slug?${searchParams}`;
  return await fetchZCal(url, 'GET');
};

/**
 * Update scheduler slug setting
 * @param {String} value
 * @return {Promise}
 */
export const patchUserSlugSetting = async (value) => {
  const url = `${zschedulerURL}/settings/slug`;
  return await fetchZCal(url, 'PATCH', {
    id: 'slug',
    value,
  });
};

/*
 * Internal get display name request
 * @return {Promise}
 */
export const getDisplayName = async () => {
  const url = `${zschedulerURL}/settings/displayName`;
  return await fetchZCal(url, 'GET');
};

/**
 * Update display name setting
 * @param {String} value
 * @return {Promise}
 */
export const patchDisplayNameSetting = async (value) => {
  const url = `${zschedulerURL}/settings/displayName`;
  return await fetchZCal(url, 'PATCH', {
    id: 'displayName',
    value,
  });
};

/*
 * Internal get profile picture request
 * @return {Promise}
 */
export const getProfilePicture = async () => {
  const url = `${zschedulerURL}/settings/picture`;
  return await fetchZCal(url, 'GET');
};

/*
 * Internal patch profile picture request
 * @param {Object} data
 * @param {string} data.id
 * @param {string} data.data base64 picture data
 * @return {Promise}
 */
export const patchProfilePicture = async (data) => {
  const url = `${zschedulerURL}/settings/picture`;
  return await fetchZCal(url, 'PATCH', data);
};

/*
 * Internal get user level profile sync lock request
 * @return {Promise}
 */
export const getUserProfileSyncLock = async () => {
  const url = `${zschedulerURL}/settings/profileSyncLocked`;
  return await fetchZCal(url, 'GET');
};

// TODO: remove this when server remove the API
/*
 * Internal patch user level profile sync lock request
 * @param {Object} data
 * @return {Promise}
 */
export const patchUserProfileSyncLock = async (data) => {
  const url = `${zschedulerURL}/settings/profileSyncLocked`;
  return await fetchZCal(url, 'PATCH', data);
};

/*
 * Internal get default date format request
 * @return {Promise}
 */
export const getDateFormatSetting = async () => {
  const url = `${zschedulerURL}/settings/dateFormat`;
  return await fetchZCal(url, 'GET');
};

/*
 * Internal patch date format request
 * @param {Object} data
 * @return {Promise}
 */
export const patchDateFormatSetting = async (data) => {
  const url = `${zschedulerURL}/settings/dateFormat`;
  return await fetchZCal(url, 'PATCH', data);
};

/*
 * Internal get default time format request
 * @return {Promise}
 */
export const getTimeFormatSetting = async () => {
  const url = `${zschedulerURL}/settings/timeFormat`;
  return await fetchZCal(url, 'GET');
};

/*
 * Internal patch time format request
 * @param {Object} data
 * @return {Promise}
 */
export const patchTimeFormatSetting = async (data) => {
  const url = `${zschedulerURL}/settings/timeFormat`;
  return await fetchZCal(url, 'PATCH', data);
};

/*
 * Internal get default zoom meeting settings request
 * @return {Promise}
 */
export const getMeetingSettings = async () => {
  const url = `${zschedulerURL}/settings/meeting`;
  return await fetchZCal(url, 'GET');
};

/**
 * @typedef ConnValidationError
 * @property {string} user
 * @property {string} error
 */

/**
 * Validate user calendar connections by email
 * @param {string[]} emails
 * @return {Promise<ConnValidationError[]>}
 */
export const validateHostConnections = async (emails) => {
  const url = `${zschedulerURL}/validators/connections`;
  return await fetchZCal(url, 'POST', {
    users: emails,
  });
};

/**
 * create routing form request
 * @param {Object} data
 * @return {Promise}
 */
export const createRoutingForm = async (data) => {
  const url = `${zschedulerURL}/routing/forms`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * get form details
 * @param {String} formId
 * @return {Promise}
 */
export const getFormDetails = async (formId) => {
  const url = `${zschedulerURL}/routing/forms/${formId}`;
  return await fetchZCal(url, 'GET');
};

/**
 * create share link for routing from
 * @param {Object} data
 * @param {String} formId
 * @return {Promise}
 */
export const createShareLinkRoutingForm = async (data, formId) => {
  const url = `${zschedulerURL}/routing/forms/${formId}/shareLink`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * list forms request
 * @param {Object} data
 * @return {Promise}
 */
export const listForms = async () => {
  const url = `${zschedulerURL}/routing/forms`;
  return await fetchZCal(url, 'GET');
};
/**
 * Update routing form request
 * @param {String} formId
 * @param {Object} data
 * @return {Promise}
 */
export const updateRoutingForm = async (formId, data) => {
  const url = `${zschedulerURL}/routing/forms/${formId}`;
  return await fetchZCal(url, 'PATCH', data);
};

/**
 * Delete routing form request
 * @param {String} formId
 * @return {Promise}
 */
export const deleteRoutingForm = (formId) => {
  const url = `${zschedulerURL}/routing/forms/${formId}`;
  return fetchZCalWithErrorBody(url, 'DELETE');
};

/**
 * Clone routing form request
 * @param {String} formId
 * @return {Promise}
 */
export const duplicateForm = (formId) => {
  const url = `${zschedulerURL}/routing/forms/${formId}/clone`;
  return fetchZCalWithErrorBody(url, 'POST');
};

/**
 * create routing logic request
 * @param {String} formId
 * @param {Object} data
 * @return {Promise}
 */
export const createRoutingLogic = async (formId, data) => {
  const url = `${zschedulerURL}/routing/logic/${formId}`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * get logic request
 * @param {String} formId
 * @return {Promise}
 */
export const getLogic = async (formId) => {
  const url = `${zschedulerURL}/routing/logic/${formId}`;
  return await fetchZCal(url, 'GET');
};

/**
 * Update routing logic request
 * @param {String} formId
 * @param {Object} data
 * @return {Promise}
 */
export const updateRoutingLogic = async (formId, data) => {
  const url = `${zschedulerURL}/routing/logic/${formId}`;
  return await fetchZCal(url, 'PATCH', data);
};

/**
 * Delete routing logic request
 * @param {String} formId
 * @return {Promise}
 */
export const deleteRoutingLogic = (formId) => {
  const url = `${zschedulerURL}/routing/logic/${formId}`;
  return fetchZCalWithErrorBody(url, 'DELETE');
};

/**
 * Internal single use link
 * @param {Object} data
 * @param {string} calendarId
 * @return {Promise}
 */
export const sendSingleUseLink = async (data, calendarId) => {
  let searchParams = '';
  if (calendarId) {
    searchParams = new URLSearchParams({user: calendarId}).toString();
  }
  const url = `${zschedulerURL}/appointments/singleUseLink?${searchParams}`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * Internal customized link
 * @param {Object} data
 * @return {Promise}
 */
export const sendCustomizedLink = async (data) => {
  const url = `${zschedulerURL}/appointments/shareLink`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * Internal get pending events
 * @param {Object} query
 * @return {Promise}
 */
export const getPendingEvents = async (query) => {
  let searchParams = '';
  if (query) {
    searchParams = new URLSearchParams(query).toString();
  }
  const url = `${zschedulerURL}/pendingEvents?${searchParams}`;
  return await fetchZCal(url, 'GET');
};

/**
 * Internal get pending event
 * @param {Object} pendingId
 * @return {Promise}
 */
export const getPendingEvent = async (pendingId) => {
  const url = `${zschedulerURL}/pendingEvents/${pendingId}`;
  return await fetchZCal(url, 'GET');
};

/**
 * Internal delete single pending event
 * @param {Scope} scope
 * @param {string} pendingEventId
 * @return {Promise}
 */
export const deletePendingEvent = async (scope, pendingEventId) => {
  const searchParams = new URLSearchParams(attachScope(scope)).toString();
  const url = `${zschedulerURL}/pendingEvents/${pendingEventId}?${searchParams}`;
  let res = {};
  try {
    res = await fetchZCalWithErrorBody(url, 'DELETE', undefined);
  } catch (error) {
    // if delete api returns 404, it means already deleted before this, we should treat as successful
    if (error.errorCode !== 404) {
      throw error;
    }
  }
  return res;
};


/**
 * cloneAppointment request
 * @param {String} apptId
 * @param {String} calId
 * @param {Object} data
 * @return {Promise}
 */
export const cloneAppointment = async (apptId, calId, data) => {
  const q = {user: calId};
  const searchParams = new URLSearchParams(q);
  const url = `${zschedulerURL}/appointments/${apptId}/clone?${searchParams.toString()}`;
  return await fetchZCal(url, 'POST', data);
};

const getAid = () => {
  try {
    const tokenPaylod = readJWTPayload(getToken());
    return tokenPaylod.aid;
  } catch (error) {}
};

/**
 * analytics report event request
 * @param {Object} param
 * @param {String} param.userId
 * @param {String} param.timeMin
 * @param {String} param.timeMax
 * @return {Promise}
 */
export const getReportsEvents = async (param) => {
  param.accountId = getAid();
  const searchParams = new URLSearchParams(param);
  const res = await fetchZCal(
    `${zschedulerURL}/reports/events?${searchParams.toString()}`,
    'GET',
    undefined
  );
  return res;
};

/**
 * get organization setting config
 */
export const getOrganizationSetting = async () => {
  return await fetchZCal(`${zschedulerURL}/organization/${getAid()}`, 'GET');
};

export const getManagedEvents = (apptId = '') => {
  // const accountId = getAid();
  return fetchZCal(`${zschedulerURL}/organization/appointments/${apptId}`, 'GET');
};

export const getManagedEventAssignDetail = (apptId = '', type = 'users') => {
  return fetchZCal(`${zschedulerURL}/organization/appointments/${apptId}/memberships?scope=${type}`, 'GET');
};

export const deleteManagedEvent = (apptId) => {
  return fetchZCalWithErrorBody(`${zschedulerURL}/organization/appointments/${apptId}`, 'DELETE');
};

export const patchManagedEvent = (apptId, data) => {
  const url = `${zschedulerURL}/organization/appointments/${apptId}`;
  return fetchZCal(url, 'PATCH', data);
};

export const assignManagedEvent = (apptId, data) => {
  const url = `${zschedulerURL}/organization/appointments/${apptId}/assign`;
  return fetchZCalWithErrorBody(url, 'POST', data);
};

export const postManagedEvent = (_, data) => {
  const url = `${zschedulerURL}/organization/appointments`;
  return fetchZCal(url, 'POST', data);
};

export const getGroup = (groupId) => {
  const url = `${zschedulerURL}/group/${groupId}`;
  return fetchZCal(url, 'GET');
};

export const unassignManagedEvent = (apptId, userId) => {
  const url = `${zschedulerURL}/organization/appointments/${apptId}/unassign/${userId}`;
  return fetchZCalWithErrorBody(url, 'DELETE');
};

export const cloneManagedEvent = (apptId) => {
  const url = `${zschedulerURL}/organization/appointments/${apptId}/clone`;
  return fetchZCal(url, 'POST');
};

/**
 * list groups request
 * @param {?Object} query
 * @return {Promise}
 */
export const getGroups = async (query) => {
  const queryParams = new URLSearchParams(query);
  const url = `${zschedulerURL}/group?${queryParams.toString()}`;
  return await fetchZCal(url, 'GET');
};

/**
 * create new team request
 * @param {Object} data
 * @return {Promise}
 */
export const createTeam = async (data) => {
  const url = `${zschedulerURL}/team`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * list teams request
 * @param {?string} teamId
 * @return {Promise}
 */
export const getTeams = async (teamId) => {
  const url = `${zschedulerURL}/team${teamId ? `/${teamId}` : ''}`;
  const resp = await fetchZCal(url, 'GET');
  // Temp Fix: Filter out invalid teams from UCS
  if (!teamId) {
    return {
      teams: resp.teams.filter((team) => !team.id.startsWith('ocsub.')),
    };
  } else {
    return resp;
  }
};

/**
 * list member teams request
 * @param {?string} userId
 * @return {Promise}
 */
export const getMemberTeams = async (userId) => {
  const query = userId ? {user: userId} : {};
  const queryParams = new URLSearchParams(query);
  const url = `${zschedulerURL}/team?${queryParams}`;
  const resp = await fetchZCal(url, 'GET');
  // Temp Fix: Filter out invalid teams from UCS
  return {
    teams: resp.teams.filter((team) => !team.id.startsWith('ocsub.')),
  };
};

/**
 * patch team request
 * @param {Object} team
 * @return {Promise}
 */
export const patchTeam = async (team) => {
  const url = `${zschedulerURL}/team/${team.id}`;
  return await fetchZCal(url, 'PATCH', team);
};

/**
 * delete team request
 * @param {String} teamId
 * @return {Promise}
 */
export const deleteTeam = async (teamId) => {
  const url = `${zschedulerURL}/team/${teamId}`;
  return await fetchZCalWithErrorBody(url, 'DELETE', undefined);
};

/**
 * list team members request
 * @param {String} teamId
 * @return {Promise}
 */
export const getTeamMembers = async (teamId) => {
  const url = `${zschedulerURL}/team/${teamId}/members`;
  return await fetchZCal(url, 'GET');
};

/**
 * add new team member request
 * @param {Object} data
 * @return {Promise}
 */
export const addTeamMember = async (data) => {
  const url = `${zschedulerURL}/team/member`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * delete team member request
 * @param {Object} data
 * @return {Promise}
 */
export const deleteTeamMember = async (data) => {
  const url = `${zschedulerURL}/team/member`;
  return await fetchZCal(url, 'DELETE', data);
};

/**
 * create new group request
 * @param {Object} data
 * @return {Promise}
 */
export const createGroup = async (data) => {
  const url = `${zschedulerURL}/group`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * patch group request
 * @param {Object} group
 * @return {Promise}
 */
export const patchGroup = async (group) => {
  const url = `${zschedulerURL}/group/${group.id}`;
  return await fetchZCal(url, 'PATCH', group);
};

/**
 * delete group request
 * @param {String} groupId
 * @return {Promise}
 */
export const deleteGroup = async (groupId) => {
  const url = `${zschedulerURL}/group/${groupId}`;
  return await fetchZCalWithErrorBody(url, 'DELETE', undefined);
};

/**
 * add new group member request
 * @param {Object} data
 * @return {Promise}
 */
export const addGroupMember = async (data) => {
  const url = `${zschedulerURL}/group/member`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * delete group member request
 * @param {Object} data
 * @return {Promise}
 */
export const deleteGroupMember = async (data) => {
  const url = `${zschedulerURL}/group/member`;
  return await fetchZCalWithErrorBody(url, 'DELETE', data);
};

/**
 * list group members request
 * @param {String} groupId
 * @return {Promise}
 */
export const getGroupMembers = async (groupId) => {
  const url = `${zschedulerURL}/group/${groupId}/members`;
  return await fetchZCal(url, 'GET');
};

/*
 * bookPollAppointment request
 * @param {Object} data
 * @param {String} pendingEventId
 * @return {Promise}
 */
export const bookPoll = async (data, pendingEventId, calendarId) => {
  let searchParams = '';
  if (calendarId) {
    searchParams = new URLSearchParams({user: calendarId}).toString();
  }
  const url = `${zschedulerURL}/pendingEvents/poll/${pendingEventId}/schedule?${searchParams}`;
  return await fetchZCal(url, 'POST', data);
};

/**
 * upload file to ZFS request
 * @param {Blob} file
 * @return {Promise}
 */
export const uploadFileToZFS = async (file) => {
  const url = `${zschedulerURL}/uploadFile`;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      fetchZCal(url, 'POST', reader.result, {
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': `filename=${file.name}`,
        'X-Upload-Content-Length': reader.result.byteLength,
      }).then(resolve).catch(reject);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

/**
 * send notify connect calendar email request
 * @param {String} calId
 * @param {Object} data
 * @return {Promise}
 */
export const sendConnectCalendarEmail = async (calId, data) => {
  const url = `${zschedulerURL}/notify/connection?user=${calId}`;
  return await fetchZCalWithErrorBody(url, 'POST', data);
};
