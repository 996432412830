import React from 'react';

import {IconRefresh} from '@zoom/sun-ui-icon';
import {useTranslation} from 'react-i18next';

import {
  ContentContainer, TypographyBody2, TypographyH5,
} from './Style.js';

import MagnifyIllustration from 'assets/svg/magnifying-glass-illustration.svg';
import RoadRepair from 'assets/svg/road-repair-illustration.svg';
import {ZmTertiaryBtn} from 'Components/ZmButtons/index.js';
import {
  ErrorExtEvent404,
  ErrorMsgBody400LinkUsed,
  ErrorMsgBody404,
  ErrorMsgBody500,
  ErrorMsgBody500BadCalendar,
  ErrorMsgHeader404,
  ErrorMsgHeader500,
  ErrorMsgHeader500BadCalendar,
} from 'Error/ErrorMsgConst.js';


/**
 * helper function to map header to a reason
 * @param {String} reason
 * @return {String}
 */
function getHeader(reason) {
  if (!reason) {
    return 'Something went wrong';
  } else if (reason === ErrorMsgBody404) {
    return ErrorMsgHeader404;
  } else if (reason === ErrorMsgBody500) {
    return ErrorMsgHeader500;
  } else if (reason === ErrorMsgBody500BadCalendar) {
    return ErrorMsgHeader500BadCalendar;
  } else if (reason === ErrorExtEvent404) {
    return 'error.errorMsgHeaderExtEvent404';
  } else if (reason === ErrorMsgBody400LinkUsed) {
    return 'booking.fetchErrorLinkUsedHead';
  } else {
    return 'Something went wrong';
  }
}

/**
 *
 * @param {string} reason
 * @return {string}
 */
function getExplanationText(reason) {
  let explanation = reason;
  switch (reason) {
    case ErrorExtEvent404:
      explanation = 'error.errorMsgBodyExtEvent404';
      break;
    default:
      break;
  }
  return explanation;
}

/**
 * Error Display Component
 * @param {Object} props
 * @param {Object} props.appointment
 * @param {String} props.header
 * @param {String} props.body
 * @return {ReactElement}
 */
export default function ErrorDisplay(props) {
  const {
    errorInfo,
    onReset,
  } = props;
  const {t} = useTranslation();

  const display500 = [
    ErrorMsgBody500, ErrorMsgBody500BadCalendar,
  ].includes(errorInfo.reason);

  return (
    <ContentContainer>
      {
        display500 ?
        <img height="120" width="120" src={RoadRepair} alt="road-repair"/>:
        <img height="120" width="120" src={MagnifyIllustration} alt="magnifying-glass"/>
      }
      <TypographyH5>
        {t(getHeader(t(errorInfo.reason)))}
      </TypographyH5>
      <TypographyBody2>
        {t(getExplanationText(errorInfo.reason))}
      </TypographyBody2>
      {onReset &&
        <ZmTertiaryBtn
          size="small"
          onClick={onReset}
          startIcon={<IconRefresh />}
        >
          {t('common.refresh')}
        </ZmTertiaryBtn>
      }
    </ContentContainer>
  );
}
