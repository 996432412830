import {
  SvgIcon,
} from '@mui/material';
import {IconEmail, IconSMS} from '@zoom/sun-ui-icon';
import {formatDuration} from 'date-fns';

import apptNotifEmailFollowUp from './templates/apptNotificationEmailFollowUp.json';
import apptNotifEmailReminder from './templates/apptNotificationEmailReminder.json';
import apptNotifSmsReminder from './templates/apptNotificationSmsReminder.json';
import workflowBookingEmail from './templates/workflowBookingEmail.json';
import workflowCalendarInvite from './templates/workflowCalendarInvite.json';
import workflowCancellationEmail from './templates/workflowCancellationEmail.json';
import workflowRescheduleEmail from './templates/workflowRescheduleEmail.json';

import {ReactComponent as BellOutlined} from 'assets/svg/bell-outlined.svg';
import {ReactComponent as CancellationOutlined} from 'assets/svg/calendar-cancellation-outlined.svg';
import {ReactComponent as ConfirmationOutlined} from 'assets/svg/calendar-confirmation-outlined.svg';
import {ReactComponent as ReconfirmationOutlined} from 'assets/svg/calendar-reconfirmation-outlined.svg';
import {ReactComponent as RescheduleOutlined} from 'assets/svg/calendar-reschedule-outlined.svg';
import {ReactComponent as FileFormatOutlined} from 'assets/svg/file-format-outlined.svg';
import {ReactComponent as HeartOutlined} from 'assets/svg/heart-outlined.svg';
import {ReactComponent as Plus} from 'assets/svg/plus.svg';
import {ReactComponent as PollOutlined} from 'assets/svg/poll-outlined.svg';
import {ReactComponent as PuzzleOutlined} from 'assets/svg/puzzle-outlined.svg';
import {ReactComponent as TeamChatOutlined} from 'assets/svg/team-chat-outlined.svg';
import {getCurrentLocale} from 'i18n';
import {DAY_MINUTES, HOUR_MINUTES, WF_ACTION_RECEIVER, WF_METHOD, WF_TRIGGER_TYPE, WF_TYPE} from 'Utils/consts';

export const SYSTEM_WORKFLOWS = [
  workflowBookingEmail,
  workflowRescheduleEmail,
  workflowCancellationEmail,
];

export const SYSTEM_EMAIL_WORKFLOWS = [
  workflowBookingEmail,
  // TODO pending server
  // workflowRescheduleEmail,
  workflowCancellationEmail,
];

export const SYSTEM_CALENDAR_WORKFLOWS = [
  workflowCalendarInvite,
];


export const SYSTEM_INDIVIDUAL_WORKFLOWS = [
  apptNotifEmailReminder,
  apptNotifSmsReminder,
  apptNotifEmailFollowUp,
];

// UI-only calendar message template const
export const CALENDAR_TEMPLATE = {
  'body': 'Event Name: {{event_name}}\n\n{{event_description}}{{event_location}}{{questions_and_answers}}',
  'subject': '{{appointment_summary}} - {{attendee_full_name}} and {{organizer_name}}',
  'method': WF_METHOD.CALENDAR,
  'receiver': WF_ACTION_RECEIVER.BOOKER,
  'variant': WF_TYPE.CALENDAR_INVITE,
};

// answer1...answer10, available in any place
const ANSWER_VARIABLES = Array.from({length: 10}, (_, i)=> i+1).map((i)=> `answer${i}`);

export const EMAIL_VARIABLES = [
  'appointment_summary',
  'attendee_count',
  'attendee_email',
  'attendee_first_name',
  'attendee_full_name',
  'attendee_last_name',
  'attendee_phone_number',
  'coming_time',
  'event_date',
  'event_duration',
  'event_description',
  'event_location',
  'event_name',
  'event_time',
  'manage_event_link',
  'organizer_email',
  'organizer_name',
  'questions_and_answers',
  'reason',
  'seat_count',
  'video_conferencing',
  'zoom_meeting_link',
  ...ANSWER_VARIABLES,
];

// Supported template variables for calendar events
export const CALENDAR_EVENT_SUBJ_VARIABLES = [
  'organizer_name',
  'attendee_full_name',
  'attendee_first_name',
  'attendee_last_name',
  'event_name',
  'appointment_summary',
  'questions_and_answers',
  'event_location',
  // 'event_description', // Disable rich text-formatted variables.
  ...ANSWER_VARIABLES,
];

export const CALENDAR_EVENT_BODY_VARIABLES = [
  'organizer_name',
  'attendee_full_name',
  'attendee_first_name',
  'attendee_last_name',
  'event_name',
  'appointment_summary',
  'questions_and_answers',
  'event_location',
  'event_description',
  ...ANSWER_VARIABLES,
];

export const PRESET_TYPES = new Set([
  WF_TYPE.ADDL_RESOURCE,
  WF_TYPE.CANCELLATION,
  WF_TYPE.CONFIRMATION,
  WF_TYPE.FEEDBACK_SURVEY,
  WF_TYPE.RECONFIRMATION,
  WF_TYPE.REMINDER,
  WF_TYPE.REQUEST_FOLLOW_UP,
  WF_TYPE.RESCHEDULE,
  WF_TYPE.THANK_YOU,
]);

export const WF_WITH_EMAIL = new Set([
  WF_TYPE.ADDL_RESOURCE,
  WF_TYPE.CUSTOM,
  WF_TYPE.FEEDBACK_SURVEY,
  WF_TYPE.RECONFIRMATION,
  WF_TYPE.REMINDER,
  WF_TYPE.REQUEST_FOLLOW_UP,
  WF_TYPE.THANK_YOU,
]);

export const WF_WITH_SMS = new Set([
  WF_TYPE.ADDL_RESOURCE,
  WF_TYPE.CANCELLATION,
  WF_TYPE.CONFIRMATION,
  WF_TYPE.CUSTOM,
  WF_TYPE.FEEDBACK_SURVEY,
  WF_TYPE.RECONFIRMATION,
  WF_TYPE.REMINDER,
  WF_TYPE.REQUEST_FOLLOW_UP,
  WF_TYPE.RESCHEDULE,
  WF_TYPE.THANK_YOU,
]);

const defaultVariants = [
  WF_TYPE.CONFIRMATION,
  WF_TYPE.RESCHEDULE,
  WF_TYPE.CANCELLATION,
];

const beforeEventVariants = [
  WF_TYPE.RECONFIRMATION,
  WF_TYPE.REMINDER,
];

const afterEventVariants = [
  WF_TYPE.REQUEST_FOLLOW_UP,
  WF_TYPE.FEEDBACK_SURVEY,
  WF_TYPE.THANK_YOU,
];

const otherVariants = [
  WF_TYPE.ADDL_RESOURCE,
];

const customVariants = [
  WF_TYPE.CUSTOM,
];

const cciVariants = [
  WF_TYPE.CCI,
];

export const isDefaultWorkflow = (workflow) => {
  return !!workflow?.isSystem;
};

export const isSmsOnlyWorkflow = (workflow) => {
  // use to handle when workflow is null
  if (!workflow) return false;
  if (isDefaultWorkflow(workflow) ||
    !workflow.type) {
    return false;
  }
  if (defaultVariants.includes(workflow.type)) {
    return true;
  }
  return false;
};

export const isBeforeEventWorkflow = (workflow) => {
  if (!!workflow.type &&
    beforeEventVariants.includes(workflow.type)) {
    return true;
  }
  return false;
};

export const isAfterEventWorkflow = (workflow) => {
  if (!!workflow.type &&
    afterEventVariants.includes(workflow.type)) {
    return true;
  }
  return false;
};

export const isOtherEventWorkflow = (workflow) => {
  if (!!workflow.type &&
    otherVariants.includes(workflow.type)) {
    return true;
  }
  return false;
};

export const isCciEventWorkflow = (workflow) => {
  if (!!workflow.type &&
    cciVariants.includes(workflow.type)) {
    return true;
  }
  return false;
};

export const isCustomEventWorkflow = (workflow) => {
  const nonCustomWorkflowChecks = [
    isDefaultWorkflow,
    isSmsOnlyWorkflow,
    isBeforeEventWorkflow,
    isAfterEventWorkflow,
    isOtherEventWorkflow,
    isCciEventWorkflow,
  ];
  for (const nonCustomWorkflowCheck of nonCustomWorkflowChecks) {
    if (nonCustomWorkflowCheck(workflow)) {
      return false;
    }
  }
  if (customVariants.includes(workflow.type)) {
    return true;
  }
  console.error('unrecognized notification type');
  return true;
};

export const getWorkflowTypeIcon = (type) => {
  const iconMap = {
    [WF_TYPE.CREATE_NEW]: Plus,
    [WF_TYPE.CONFIRMATION]: ConfirmationOutlined,
    [WF_TYPE.RESCHEDULE]: RescheduleOutlined,
    [WF_TYPE.CANCELLATION]: CancellationOutlined,
    [WF_TYPE.RECONFIRMATION]: ReconfirmationOutlined,
    [WF_TYPE.REMINDER]: BellOutlined,
    [WF_TYPE.THANK_YOU]: HeartOutlined,
    [WF_TYPE.FEEDBACK_SURVEY]: PollOutlined,
    [WF_TYPE.REQUEST_FOLLOW_UP]: TeamChatOutlined,
    [WF_TYPE.ADDL_RESOURCE]: FileFormatOutlined,
    [WF_TYPE.CUSTOM]: PuzzleOutlined,
    [WF_TYPE.APPT_EMAIL_REMINDER]: IconEmail,
    [WF_TYPE.APPT_SMS_REMINDER]: IconSMS,
    [WF_TYPE.APPT_EMAIL_FOLLOW_UP]: IconEmail,
  };
  const workflowIcon = iconMap[type];
  if (!!type && !!workflowIcon) {
    return (<SvgIcon fontSize="inherit" component={workflowIcon} inheritViewBox />);
  } else {
    return (<SvgIcon fontSize="inherit" component={PuzzleOutlined} inheritViewBox />);
  }
};

export const getWorkflowName = (t, workflow) => {
  const systemWorkflowNameMap = {
    [WF_TYPE.CONFIRMATION]: t('landing.bookingConfirmationEmail'),
    [WF_TYPE.RESCHEDULE]: t('landing.rescheduleEmail'),
    [WF_TYPE.CANCELLATION]: t('landing.cancellationEmail'),
    [WF_TYPE.CALENDAR_INVITE]: t('landing.calendarInvite'),
  };
  const workflowNameMap = {
    [WF_TYPE.APPT_EMAIL_REMINDER]: t('landing.reminderEmail'),
    [WF_TYPE.APPT_SMS_REMINDER]: t('landing.reminderSMS'),
    [WF_TYPE.APPT_EMAIL_FOLLOW_UP]: t('landing.followUpEmail'),
    [WF_TYPE.CREATE_NEW]: t('landing.createCustomNotification'),
    [WF_TYPE.CONFIRMATION]: t('landing.bookingConfirmationSMS'),
    [WF_TYPE.RESCHEDULE]: t('landing.rescheduleSMS'),
    [WF_TYPE.CANCELLATION]: t('landing.cancellationSMS'),
    [WF_TYPE.RECONFIRMATION]: process.env.REACT_APP_ENABLE_SMS === 'true' ?
      t('landing.reconfirmationEmailSMS') :
      t('landing.reconfirmationEmail'),
    [WF_TYPE.REMINDER]: process.env.REACT_APP_ENABLE_SMS === 'true' ?
      t('landing.reminderEmailSMS') :
      t('landing.reminderEmail'),
    [WF_TYPE.THANK_YOU]: process.env.REACT_APP_ENABLE_SMS === 'true' ?
      t('landing.thankYouEmailSMS') :
      t('landing.thankYouEmail'),
    [WF_TYPE.FEEDBACK_SURVEY]: process.env.REACT_APP_ENABLE_SMS === 'true' ?
      t('landing.quickSurveyEmailSMS') :
      t('landing.quickSurveyEmail'),
    [WF_TYPE.REQUEST_FOLLOW_UP]: process.env.REACT_APP_ENABLE_SMS === 'true' ?
      t('landing.followUpEmailSMS') :
      t('landing.followUpEmail'),
    [WF_TYPE.ADDL_RESOURCE]: process.env.REACT_APP_ENABLE_SMS === 'true' ?
      t('landing.additionalResourcesEmailSMS') :
      t('landing.additionalResourcesEmail'),
  };
  if (workflow?.isSystem) {
    return systemWorkflowNameMap[workflow?.type];
  }
  if (Object.keys(workflowNameMap).includes(workflow?.type)) {
    return workflowNameMap[workflow?.type];
  }
  if (!workflow?.type || workflow?.type === 'custom') {
    return workflow?.name;
  }
  console.error('Unexpected workflow type');
  return workflow?.name;
};

export const workflowHasHostSMS = (workflow) => {
  const workflowHasHostSMS = (workflow?.text || []).some((smsItem) => smsItem.receiver !== WF_ACTION_RECEIVER.BOOKER);
  return (workflowHasHostSMS);
};

export const workflowActive = (workflow) => {
  return workflow?.appointmentId?.length > 0;
};

export const convertMinutes = (minutes, t) => {
  if (minutes === 0) {
    return t('landing.immediately');
  }

  const absMinutes = Math.abs(minutes);

  let days = 0;
  let hours = 0;
  let min = 0;
  days = Math.floor(absMinutes / DAY_MINUTES);
  hours = Math.floor((absMinutes % DAY_MINUTES) / HOUR_MINUTES);
  min = (absMinutes % HOUR_MINUTES);
  return formatDuration(
    {
      days,
      hours,
      minutes: min,
    },
    {
      locale: getCurrentLocale(),
    });
};

export const renderTriggerSummary = (min, type, t) => {
  if (!type) {
    return '';
  }
  let minutesArr = min;
  if (!Array.isArray(min)) {
    minutesArr = [min];
  }
  return minutesArr.map((minutes) => {
    const duration = convertMinutes(minutes, t);
    if (minutes === 0 && type === WF_TRIGGER_TYPE.EVENT_START) {
      return t('landing.whenEventStarts');
    } else if (type === WF_TRIGGER_TYPE.EVENT_START) {
      if (minutes <= 0) {
        return t('landing.durationAfterEventStarts', {duration});
      }
      return t('landing.durationBeforeEvent', {duration});
    } else if (type === WF_TRIGGER_TYPE.EVENT_END) {
      return t('landing.durationAfterEventEnds', {duration});
    } else if (type === WF_TRIGGER_TYPE.EVENT_BOOKED) {
      return t('landing.durationAfterBooking', {duration});
    } else if (type === WF_TRIGGER_TYPE.EVENT_CANCEL) {
      return t('landing.durationAfterCancelling', {duration});
    } else {
      console.log('unrecognized trigger type');
      return null;
    }
  }).filter((x) => x).join(', ');
};

export const renderActionSummary = (method, action) => {
  let medium = 'Email';
  if (method === WF_METHOD) {
    medium = 'Text';
  }

  return `${medium} the ${action.receiver}`;
};
