/**
 * https://github.com/tbreuss/eventbus
 * @class
 */
export class EventBus {
  /**
   * Create an eventBus
   */
  constructor() {
    this.events = {};
  }

  /**
   * Adds listener to EventBus
   * @param {string} type The name of the event to listen for
   * @param {function} callback Callback to call when event was triggered
   * @param {object} scope The scope in which the callback shall be executed
   * @param  {...any} args Any number of args to be passed to the callback
   */
  on(type, callback, scope, ...args) {
    if (typeof this.events[type] === 'undefined') {
      // Check if there is already event of this type registered
      this.events[type] = []; // If not, create array for it
    }
    this.events[type].push({scope, callback, args}); // Finally push new event to events array
  }

  /**
   * Removes listener from EventBus
   * @param {string} type The name of the event to remove
   * @param {function} callback Callback of the event to remove
   * @param {object} scope The scope of the to be removed event
   */
  off(type, callback, scope) {
    if (typeof this.events[type] === 'undefined') {
      // Check if event of this type exists
      return; // If not just return
    }

    // keep all elements that aren't equal to the passed event
    const filterFn = (event) =>
      event.scope !== scope || event.callback !== callback;
    this.events[type] = this.events[type].filter(filterFn);
  }

  /**
   * Emits an event on the EventBus
   * @param {string} type Type of event to emit
   * @param {object} target The caller
   * @param {...any} args Any number of args to be passed to the callback
   */
  emit(type, target, ...args) {
    if (typeof this.events[type] === 'undefined') {
      // Check if any event of the passed type exists
      return; // If not, quit method
    }

    const bag = {type, target};

    const events = this.events[type].slice(); // Little hack to clone array

    for (const event of events) {
      // Iterate all events
      if (event && event.callback) {
        // Check if callback of event is set
        event.callback.apply(event.scope, [bag, ...args, ...event.args]); // Call callback
      }
    }
  }
}

export const global = new EventBus();
