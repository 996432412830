import React, {useEffect, useState} from 'react';

import {DialogContentText} from '@mui/material';
import {useTranslation} from 'react-i18next';

import ConfirmDialog from 'Components/ConfirmDialog';
import {global as Bus} from 'Utils/EventBus';
import {forceRefreshClientToken, inZoomClient} from 'Utils/integration';

/**
 * Token Expire Prompt Component
 * @return {ReactElement}
 */
export default function TokenExpireModal() {
  const [showModal, setShowModal] = useState(false);
  const {t} = useTranslation();
  useEffect(() => {
    Bus.on('tokenExpired', () => {
      setShowModal(true);
    });
  }, []);
  return (
    <ConfirmDialog
      switchOrder
      open={showModal}
      onConfirm={async () => {
        if (inZoomClient) {
          await forceRefreshClientToken();
          setShowModal(false);
        } else {
          window.parent.postMessage({type: 'reload'}, '*');
        }
      }}
      onCancel={() => setShowModal(false)}
      titleContent={t('integration.sessionExpireTitle')}
      bodyContent={
        <DialogContentText>
          {t('integration.sessionExpireDesc')}
        </DialogContentText>
      }
      confirmText={t('common.signin')}
      cancelText={t('common.cancel')}
    />
  );
}
